import {
  BLOG_AVATAR01,
  BLOG_AVATAR02,
  BLOG_AVATAR03,
  BLOG_AVATAR04,
  BLOG_AVATAR05,
  BLOG_AVATAR06,
  BLOG_IMG01,
  BLOG_IMG02,
  BLOG_IMG03,
  H2_BLOG_IMG01,
  H2_BLOG_IMG02,
  H2_BLOG_IMG03,
  H3_BLOG_IMG01,
  H3_BLOG_IMG02,
  H3_BLOG_IMG03,
  H3_BLOG_IMG04,
  H3_BLOG_IMG05,
  H3_BLOG_IMG06,
  H4_BLOG_IMG01,
  H4_BLOG_IMG02,
  H4_BLOG_IMG03,
  RC_POST01,
  RC_POST02,
  RC_POST03,
  RC_POST04,
} from "../lib/assets";

export const BLOGS_LIST_TWO = [
  {
    id: 1,
    href: "/blog-details",
    src: H2_BLOG_IMG01,
    src2: H3_BLOG_IMG01,
    src3: H4_BLOG_IMG01,
    src4: BLOG_IMG01,
    tag: "Development",
    tag2: "Car Insurance",
    tag3: "Development",
    tagHref: "/blog",
    title: "Car insurance is a vital component of responsible vehicle ownership",
    subtitle:
      "Protect your wheels with our comprehensive Motor insurance, covering damages, theft, and unforeseen events. Drive with peace of mind, knowing you're insured for the road ahead.",
    //avatar: BLOG_AVATAR01,
    author: "Kat Doven",
    author2: "Admin",
    createdAt: "22 Jan, 2023",
  },
  {
    id: 2,
    href: "/blog-details",
    src: H2_BLOG_IMG02,
    src2: H3_BLOG_IMG02,
    src3: H4_BLOG_IMG02,
    src4: BLOG_IMG02,
    tag: "Business",
    tag2: "Disability Insurance",
    tag3: "Development",
    tagHref: "/blog",
    title: "The Importance of Disability Insurance for Small Business Owners..",
    subtitle:
      "Everything you need to start building area atching presence for your business.",
    //avatar: BLOG_AVATAR01,
    author: "Kat Doven",
    author2: "Admin",
    createdAt: "22 Jan, 2023",
  },
  {
    id: 3,
    href: "/blog-details",
    src: H2_BLOG_IMG03,
    src2: H3_BLOG_IMG03,
    src3: H4_BLOG_IMG03,
    src4: BLOG_IMG03,
    tag: "Tax Advisory",
    tag2: "Life Insurance",
    tag3: "Development",
    tagHref: "/blog",
    title: "Top 5 reasons why you need Life Insurance in your 30s",
    subtitle:
      "Everything you need to start building area atching presence for your business.",
    //avatar: BLOG_AVATAR01,
    author: "Kat Doven",
    author2: "Admin",
    createdAt: "22 Jan, 2023",
  },
];


export const BLOG_CATEGORIES = [
  {
    label: "Business",
    value: "02",
  },
  {
    label: "Consulting",
    value: "08",
  },
  {
    label: "Corporate",
    value: "05",
  },
  {
    label: "Design",
    value: "02",
  },
  {
    label: "Fashion",
    value: "11",
  },
  {
    label: "Marketing",
    value: "12",
  },
];

export const RECENT_BLOGS = [
  {
    id: 1,
    createdAt: "22 Jan, 2023",
    href: "/blog-details",
    src: RC_POST01,
    title: "Top 5 Reasons Why You Need Life Insurance in Your 30s",
  },
  {
    id: 2,
    createdAt: "22 Jan, 2023",
    href: "/blog-details",
    src: RC_POST02,
    title: "The Importance of Disability Insurance for Small Business Owners.",
  },
  {
    id: 3,
    createdAt: "22 Jan, 2023",
    href: "/blog-details",
    src: RC_POST03,
    title: "Car insurance is a vital component of responsible vehicle ownership",
  },
  
];

export const BLOG_TAGS = ["Car Insurance", "Travel Insurance", "Life Insurance", "Corporate Insurance", "term Insurance"];

export const BLOG_PAGE_LIST = [
  {
   
    id: 1,
    createdAt: "22 Jan, 2023",
   // title: "Life Insurance",
    subtitle:
      "Top 5 reasons why you need Life Insurance in your 30s",
    tag: "Life",
  //  author: "Jayalakshmi",
    src: H4_BLOG_IMG03,
  //  authorSrc: BLOG_AVATAR01,
    href: "/blog-details#TopFiveReasons",
    tagHref: "/blog",
  },
  {
    id: 2,
    createdAt: "22 Jan, 2023",
    //title: "Health Insurance",
    subtitle:
      "The Importance of Disability Insurance for Small Business Owners.",
    tag: "Disability Insurance",
   // author: "Innayat",
    src: H4_BLOG_IMG02,
   // authorSrc: BLOG_AVATAR02,
    href: "/blog-details",
    tagHref: "/blog",
  },
  {
    id: 3,
    createdAt: "22 Jan, 2023",
    //title: "Health Insurance",
    subtitle:
      "Car insurance is a vital component of responsible vehicle ownership",
    tag: "Car Insurance",
   // author: "Innayat",
    src: H4_BLOG_IMG01,
   // authorSrc: BLOG_AVATAR02,
    href: "/blog-details",
    tagHref: "/blog",
  },
];
