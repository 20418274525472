import React , { useState } from "react";
import { Link } from "react-router-dom";
import { LOGO } from "../../lib/assets";
import { HashLink } from "react-router-hash-link";


export const FooterTwo = () => {
  const [Email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('entry.1045781291', Email);

    fetch('https://docs.google.com/forms/d/e/1FAIpQLSct5y1BPg23Rk-VuY2HX3sb1PCKUvV7cYvqWvz5YIyGENC5Dw/formResponse', {
      method: 'POST',
      body: formData,
      mode:"no-cors"
    })
    .then(() => {
      alert('Form submitted successfully');
      setEmail('');
    })
    .catch((error) => {
      console.error('Error submitting form:', error);
      alert('Error submitting form: ' + error);
    });
  };
  return (
    <footer>
      <div className="footer-area-two footer-bg-two">
        <div className="footer-top-two">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-7 ">
                <div className="footer-widget">
                  <div className="fw-logo">
                    <h4 className="fw-title">VR Policy Market</h4>
                {/*  <Link to="/">
                       <img src={LOGO} alt="Logo"   style={{ maxWidth: '150px', height: 'auto' }} />
                      </Link>
                    */}
                  </div>

                  <div className="footer-content">
                   
                    <div className="footer-info">
                      <ul className="list-wrap">
                        <li>
                          <div className="icon">
                         {/* <i className="fas fa-phone invert-icon" ></i>
                          <i className="fab fa-whatsapp"></i>
*/}
                          <i className="flaticon-phone-call"></i>

                          </div>
                          <div className="content">
                          <div className="phone_number">

                            <a href="tel: +91 79931 67897"> +91 79931 67897</a>
                            </div>
                          </div>
                        </li>
                         <li>
                        <div className="icon">
                        <div className="phone_number">
                        <i className="flaticon-mail"></i>

                    {/*    <i className="fas fa-phone invert-icon" ></i>
                        <i className="fab fa-whatsapp"></i> */}
                        </div>
                        </div>
                        <div className="content">
                        <div className="phone_number">


                        <a href="mailto:support@vrpolicymarket.com">support@vrpolicymarket.com </a>
                          
                          </div>


                        </div>
                      </li>
                    
                        <li>
                          <div className="icon">
                            <i className="flaticon-clock"></i>
                          </div>
                          <div className="content">
                            <p>
                              Mon – Sun: 24/7 Support
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-5 col-sm-3">
                <div className="footer-widget">
                  <h4 className="fw-title">Menu</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/about-4">Company</Link>
                      </li>
                      <li>
                        <Link to="/services-4">Our Services</Link>
                      </li>
                      <li>
                        <Link to="/blog">Our Blog</Link>
                      </li>
                  {/*    <li>
                        <Link to="/contact">Press media</Link>
                      </li>
                     
                      <li>
                        <Link to="/contact">Privacy Policy</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-5 col-sm-3">
                <div className="footer-widget">
                  <h4 className="fw-title">Quick Links</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                      <li><HashLink to="/#testimonialsection" >
                      Testimonials
                          </HashLink></li>

                   {/*   <li>
                        <Link to="/contact">Partners</Link>
                      </li>*/}
                      
                      <li>
                        <HashLink to="/#EstimateAreaOneForm">Get A Quote</HashLink>
                      </li>
                        
                      
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                   {/*   <li>
                        <Link to="/contact">Pricing</Link>
                      </li>*/}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-5 col-sm-3">
                <div className="footer-widget">
                  <h4 className="fw-title">Insurance Regulatory</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                      <a href="  https://irdai.gov.in/" target="_blank">IRDAI</a>
                      </li>
                     

                      <li>
                      <a href="https://iib.gov.in/" target="_blank">IIB</a>
                      </li>
                      
                      <li>
                      <a href="https://www.cioins.co.in/" target="_blank">Ombudsman</a>
                      </li>
                
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-3">
                <div className="footer-widget">
                  <h4 className="fw-title">Our Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>
                      Sign up to our weekly newsletter 
                    </p>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="email"
                        placeholder="E-mail"
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <button type="submit">Subscribe</button>
                    </form>
                    <div className="footer-social footer-social-two">
                      <ul className="list-wrap">
                        <li>
                          <a href="https://www.facebook.com/people/VR-Policy-Market/61565985988741/?mibextid=ZbWKwL">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/vrpolicymarket?igsh=MW1heWZjZ2xzaTdyOQ==">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/@vrpolicymarket">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom-two">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text-two text-center">
                  <p>Copyright © Ratoon Infotech | All Right Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
