import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SD_WORKING_IMG03,
  SD_WORKING_IMG04,
  SERVICES_DETAILS04,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";
import { ServicesDetailsLifeFaq } from "../../components/ServicesDetails/ServicesDetailsLifeFaq";

const ServicesDetailsPageFour = () => {
  const InsuranceType = "Life"
  return (
    <Layout breadcrumb={"Services"} title={"Life Insurance"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper InsuranceType={InsuranceType}>
        <div className="services-details-wrap">
          <div className="services-details-content">
            <div className="sd-inner-wrap-four">
              <div className="row align-items-center">
                <div className="col-55">
                  <div className="thumb">
                    <img src={SERVICES_DETAILS04} alt="" />
                  </div>
                </div>
                <div className="col-45">
                  <div className="content">
                    <h2 className="title">
                    Protecting Your Loved Ones' Future
                    </h2>
                    <p>Life insurance is a contract between you and an insurance company that provides a death benefit to your beneficiaries if you pass away. In exchange, you pay premiums (either monthly, quarterly, or annually) to maintain your coverage.</p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Settle outstanding debts
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Maintain living expenses
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Fund education costs
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Achieve long-term goals
                      </li> 
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="services-details-list-three">
              <ul className="list-wrap">
                <li>
                  <div className="icon">
                    <i className="flaticon-family"></i>
                  </div>
                  <div className="content">
                    <h4 className="title">Term Life Insurance</h4>
                    <p>
                    Provides coverage for a specified term (e.g., 10, 20, or 30 years).
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <i className="flaticon-winner"></i>
                  </div>
                  <div className="content">
                    <h4 className="title">Whole Life Insurance</h4>
                    <p>
                    Offers lifetime coverage, as long as premiums are paid.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <p>
                    A vital component of financial planning, providing a safety net for your loved ones in the event of your passing. It helps ensure that they can maintain their standard of living, achieve their goals, and realize their dreams, even if you're no longer around.
                    </p>
            <div className="industrial-working-wrap mt-50">
              <h2 className="title-two">
                Benefits of Life Insurance
              </h2>
              <p className="info-one">
              Life insurance provides numerous benefits, including financial security for your loved ones in the event of your passing, a tax-free death benefit, and cash value accumulation over time. It also offers flexibility to adjust your policy as your needs change, and peace of mind knowing that your family is protected. Additionally, life insurance can be used to pay off outstanding debts, such as a mortgage or car loan, and can even be used to fund education expenses or achieve long-term goals. 
              </p>
              <div className="thumb">
                  <img src={SD_WORKING_IMG04} alt="" />
                </div> 
              {/* <div className="row">
                <div className="col-md-6">
                  <img src={SD_WORKING_IMG03} alt="" />
                </div>
               <div className="col-md-6">
                  <img src={SD_WORKING_IMG04} alt="" />
                </div> 
              </div> */}
              <p>
              Furthermore, some policies offer living benefits, such as accelerated death benefit or critical illness coverage, which can provide financial support during a terminal illness or critical condition. Overall, life insurance is an essential investment in your loved ones' future, providing a safety net and financial protection when they need it most.
              </p>
              <h2 className="title-two">
              How to Choose the Right Policy
              </h2>

            </div>

            {/* faq */}
            <ServicesDetailsLifeFaq />
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
   {/*   <BrandSeven />*/}
    </Layout>
  );
};

export default ServicesDetailsPageFour;
