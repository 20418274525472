import {
  H2_SERVICES_IMG01,
  H2_SERVICES_IMG02,
  H2_SERVICES_IMG03,
  H2_SERVICES_IMG04,
  H4_SERVICES_IMG01,
  H4_SERVICES_IMG02,
  H4_SERVICES_IMG03,
  H4_SERVICES_IMG04,
  H4_SERVICES_IMG05,
  H4_SERVICES_IMG06,
  SERVICES_IMG01,
  SERVICES_IMG02,
  SERVICES_IMG03,
  SERVICES_IMG04,
  SERVICES_IMG05,
  SERVICES_ITEM_SHAPE,
} from "../lib/assets";

export const SERVICES_TWO_LIST = [
  {
    id: 1,
    src: H2_SERVICES_IMG01,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-piggy-bank",
    href: "/services-details",
    title: "Finance Planning",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 2,
    src: H2_SERVICES_IMG02,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-calculator",
    href: "/services-details",
    title: "Tax File Audit",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 3,
    src: H2_SERVICES_IMG03,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-money",
    href: "/services-details",
    title: "Investment Idea",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 4,
    src: H2_SERVICES_IMG04,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-layers",
    href: "/services-details",
    title: "Risk Management",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
];

export const SERVICES_THREE_LIST = [
  {
    id: 1,
    src: H4_SERVICES_IMG01,
    icon: "flaticon-healthcare",
    href: "/services-details-2",
    title: "Health Insurance",
    subtitle: "More than just a safety net.It's a vital investment in your overall well-being",
  },
  {
    id: 2,
    src: H4_SERVICES_IMG02,
    icon: "flaticon-protection",
    href: "/services-details",
    title: "Motor Insurance",
    subtitle: "Protect your investment and ensuring peace of mind on the road.",
  },
  {
    id: 3,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-ship",
    href: "/services-details-4",
    title: "Life Insurance",
    subtitle: " provides a financial safety net for your loved ones in the event of your passing",
  },
  {
    id: 4,
    src: H4_SERVICES_IMG06,
    icon: "flaticon-house",
    href: "/services-details-5",
    title: "Corporate Group Insurance",
    subtitle: "Financial protection to the insured individuals and their families.",
  },
  {
    id: 5,
    src: H4_SERVICES_IMG03,
    icon: "flaticon-ship",
    href: "/services-details-3",
    title: "Travel Insurance",
    subtitle: "Travel insurance provides financial protection and assistance to travelers",
  },
  {
    id: 6,
    src: H4_SERVICES_IMG05,
    icon: "flaticon-ship",
    href: "/services-details-6",
    title: "Home Insurance",
    subtitle: "Provides financial protection and peace of mind to homeowners",
  },
];

export const SERVICES_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-business-presentation",
    href: "/services-details",
    title: "Marketing Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 2,
    icon: "flaticon-rocket",
    href: "/services-details",
    title: "SEO Consultancy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 3,
    icon: "flaticon-mission",
    href: "/services-details",
    title: "Target Audience",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
];

export const SERVICES_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-briefcase",
    href: "/services-details",
    src: SERVICES_IMG01,
    title: "Life Insurance",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 2,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG05,
    title: "Term Insurance",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 3,
    icon: "flaticon-money",
    href: "/services-details",
    src: SERVICES_IMG04,
    title: "Corporate Insurance",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 4,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG02,
    title: "Motor Insurance",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 5,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG04,
    title: "Travel Insurance",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 5,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG05,
    title: "Health Insurance",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
];
