import { RIGHT_ARROW } from "../lib/assets";

export const FEATURE_TWO_LIST = [
  {
    id: 1,
    icon: "flaticon-profit",
    title: "Growing Business",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 2,
    icon: "flaticon-investment",
    title: "Finance Investment",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 3,
    icon: "flaticon-taxes",
    title: "Tax Advisory",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
];

export const FEATURE_THREE_LIST = [
  {
    id: 1,
    title: "Finance Planning",
    subtitle:
      "Morem Ipsum Dolor Sittemet Consectetur Adipiscing Elitflorai Psum Dolor.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
  {
    id: 2,
    title: "Business Consulting",
    subtitle:
      "Morem Ipsum Dolor Sittemet Consectetur Adipiscing Elitflorai Psum Dolor.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
  {
    id: 3,
    title: "Tax Advisory",
    subtitle:
      "Morem Ipsum Dolor Sittemet Consectetur Adipiscing Elitflorai Psum Dolor.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
  {
    id: 4,
    title: "Business Audit",
    subtitle:
      "Morem Ipsum Dolor Sittemet Consectetur Adipiscing Elitflorai Psum Dolor.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
];

export const FEATURE_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-life-insurance",
    title: "More coverage",
    subtitle: "Get more coverage at a minimal premium. Maximize protection without stretching your budget!",
  },
  {
    id: 2,
    icon: "flaticon-handshake",
    title: "Expert Startegy",
    subtitle: "It simplifies the decision-making  process, ensuring maximum protection at the best value.",
  },
  {
    id: 3,
    icon: "flaticon-money",
    title: "Competitive Premium",
    subtitle: "Protect what matters most with our competitively priced, high-value insurance solutions",
  },
];

export const FEATURE_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-puzzle-piece",
    title: "Quality Services",
    subtitle: "Eiusmod Temporincididunt Ut Labore Magna Aliqua Quisery.",
  },
  {
    id: 2,
    icon: "flaticon-inspiration",
    title: "Innovation Ideas",
    subtitle: "Eiusmod Temporincididunt Ut Labore Magna Aliqua Quisery.",
  },
  {
    id: 3,
    icon: "flaticon-profit",
    title: "Business Growth",
    subtitle: "Eiusmod Temporincididunt Ut Labore Magna Aliqua Quisery.",
  },
];
