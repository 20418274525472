import React, { useState } from "react";
import { ESTIMATE_TIME, H4_SERVICES_SHAPE } from "../../lib/assets";
import { EstimateForm } from "./EstimateForm";

export const EstimateAreaOne = () => {
  // State to manage the active tab
  const [activeTab, setActiveTab] = useState("Health-Insurance");

  // Handle tab change
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <section id="EstimateAreaOneForm" className="estimate-area" style={{ paddingTop: '50px'}}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="estimate-content">
              <div className="section-title-two mb-30 tg-heading-subheading animation-style2">
                <span className="sub-title tg-element-title">
                  GET A FREE ESTIMATE
                </span>
                <h2 className="title tg-element-title">
                  Get an insurance quote From Our Expertise
                </h2>
              </div>

              <div className="estimate-tab-wrap">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${activeTab === "Health-Insurance" ? "active" : ""}`}
                      id="Health-Insurance"
                      onClick={() => handleTabChange("Health-Insurance")}
                      type="button"
                      role="tab"
                      aria-controls="Health-Insurance-pane"
                      aria-selected={activeTab === "Health-Insurance"}
                    >
                      Health
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${activeTab === "Life-Insurance" ? "active" : ""}`}
                      id="Life-Insurance"
                      onClick={() => handleTabChange("Life-Insurance")}
                      type="button"
                      role="tab"
                      aria-controls="Life-Insurance-pane"
                      aria-selected={activeTab === "Life Insurance"}
                    >
                      Life
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${activeTab === "Motor-Insurance" ? "active" : ""}`}
                      id="Motor-Insurance"
                      onClick={() => handleTabChange("Motor-Insurance")}
                      type="button"
                      role="tab"
                      aria-controls="Motor-Insurance-pane"
                      aria-selected={activeTab === "Motor-Insurance"}
                    >
                      Motor
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${activeTab === "Home-Insurance" ? "active" : ""}`}
                      id="Home-Insurance"
                      onClick={() => handleTabChange("Home-Insurance")}
                      type="button"
                      role="tab"
                      aria-controls="Home-Insurance-pane"
                      aria-selected={activeTab === "Home-Insurance"}
                    >
                      Home
                    </button>
                  </li>
                  
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${activeTab === "Travel-Insurance" ? "active" : ""}`}
                      id="Travel-Insurance"
                      onClick={() => handleTabChange("Travel-Insurance")}
                      type="button"
                      role="tab"
                      aria-controls="Travel-Insurance-pane"
                      aria-selected={activeTab === "Travel-Insurance"}
                    >
                      Travel
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${activeTab === "SME Business-Insurance" ? "active" : ""}`}
                      id="SME Business-Insurance"
                      onClick={() => handleTabChange("SME Business-Insurance")}
                      type="button"
                      role="tab"
                      aria-controls="SME Business-Insurance-pane"
                      aria-selected={activeTab === "SME Business-Insurance"}
                    >
                      SME Business
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${activeTab === "Corporate-Insurance" ? "active" : ""}`}
                      id="Corporate-Insurance"
                      onClick={() => handleTabChange("Corporate-Insurance")}
                      type="button"
                      role="tab"
                      aria-controls="Corporate-Insurance-pane"
                      aria-selected={activeTab === "Corporate-Insurance"}
                    >
                      Corporate
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                <div
                    className={`tab-pane fade ${activeTab === "Health-Insurance" ? "show active" : ""}`}
                    id="Health-Insurance-pane"
                    role="tabpanel"
                    aria-labelledby="Health-Insurance"
                    tabindex="0"
                  >
                    <EstimateForm tab={activeTab} />
                  </div>
                  <div
                    className={`tab-pane fade ${activeTab === "Life-Insurance" ? "show active" : ""}`}
                    id="Life-Insurance-pane"
                    role="tabpanel"
                    aria-labelledby="Life-Insurance"
                    tabindex="0"
                  >
                    <EstimateForm tab={activeTab} />
                  </div>
                  <div
                    className={`tab-pane fade ${activeTab === "Motor-Insurance" ? "show active" : ""}`}
                    id="Motor-Insurance-pane"
                    role="tabpanel"
                    aria-labelledby="Motor-Insurance"
                    tabindex="0"
                  >
                    <EstimateForm tab={activeTab} />
                  </div>
                  
                  <div
                    className={`tab-pane fade ${activeTab === "Home-Insurance" ? "show active" : ""}`}
                    id="Home-Insurance-pane"
                    role="tabpanel"
                    aria-labelledby="Home-Insurance"
                    tabindex="0"
                  >
                    <EstimateForm tab={activeTab} />
                  </div>
                  <div
                    className={`tab-pane fade ${activeTab === "Travel-Insurance" ? "show active" : ""}`}
                    id="Travel-Insurance-pane"
                    role="tabpanel"
                    aria-labelledby="Travel-Insurance"
                    tabindex="0"
                  >
                    <EstimateForm tab={activeTab} />
                  </div>
                  <div
                    className={`tab-pane fade ${activeTab === "SME Business-Insurance" ? "show active" : ""}`}
                    id="SME Business-Insurance-pane"
                    role="tabpanel"
                    aria-labelledby="SME Business-Insurance"
                    tabindex="0"
                  >
                    <EstimateForm tab={activeTab} />
                  </div>
                  <div
                    className={`tab-pane fade ${activeTab === "Corporate-Insurance" ? "show active" : ""}`}
                    id="Corporate-Insurance-pane"
                    role="tabpanel"
                    aria-labelledby="Corporate-Insurance"
                    tabindex="0"
                  >
                    <EstimateForm tab={activeTab} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="estimate-img text-center">
              <img src={ESTIMATE_TIME} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="estimate-shape">
        <img
          src={H4_SERVICES_SHAPE}
          alt=""
          data-aos="fade-left"
          data-aos-delay="200"
        />
      </div>
    </section>
  );
};

{/*import React from "react";
import { ESTIMATE_TIME, H4_SERVICES_SHAPE } from "../../lib/assets";
import { EstimateForm } from "./EstimateForm";

export const EstimateAreaOne = () => {
  return (
    <section id = "EstimateAreaOneForm" className="estimate-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="estimate-content">
              <div className="section-title-two mb-30 tg-heading-subheading animation-style2">
                <span className="sub-title tg-element-title">
                  GET A FREE ESTIMATE
                </span>
                <h2 className="title tg-element-title">
                  Get an insurance quote From Our Expertise
                </h2>
              </div>

              <div className="estimate-tab-wrap">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-insurance"
                      data-bs-toggle="tab"
                      data-bs-target="#home-insurance-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-insurance-pane"
                      aria-selected="true"
                    >
                      Home
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="vehicle-insurance"
                      data-bs-toggle="tab"
                      data-bs-target="#vehicle-insurance-pane"
                      type="button"
                      role="tab"
                      aria-controls="vehicle-insurance-pane"
                      aria-selected="false"
                    >
                      Vehicles
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="health-insurance"
                      data-bs-toggle="tab"
                      data-bs-target="#health-insurance-pane"
                      type="button"
                      role="tab"
                      aria-controls="health-insurance-pane"
                      aria-selected="false"
                    >
                      Health
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="life-insurance"
                      data-bs-toggle="tab"
                      data-bs-target="#life-insurance-pane"
                      type="button"
                      role="tab"
                      aria-controls="life-insurance-pane"
                      aria-selected="false"
                    >
                      Life
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home-insurance-pane"
                    role="tabpanel"
                    aria-labelledby="home-insurance"
                    tabindex="0"
                  >
                    <EstimateForm />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="vehicle-insurance-pane"
                    role="tabpanel"
                    aria-labelledby="vehicle-insurance"
                    tabindex="0"
                  >
                    <EstimateForm />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="health-insurance-pane"
                    role="tabpanel"
                    aria-labelledby="health-insurance"
                    tabindex="0"
                  >
                    <EstimateForm />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="life-insurance-pane"
                    role="tabpanel"
                    aria-labelledby="life-insurance"
                    tabindex="0"
                  >
                    <EstimateForm />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="estimate-img text-center">
              <img src={ESTIMATE_TIME} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="estimate-shape">
        <img
          src={H4_SERVICES_SHAPE}
          alt=""
          data-aos="fade-left"
          data-aos-delay="200"
        />
      </div>
    </section>
  );
};
*/}