import React from "react";

export const ServicesDetailsLifeFaq = () => {
  return (
    <div className="accordion-wrap-three">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Assess Your Needs
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              Before choosing a life insurance policy, it's essential to assess your needs. Consider your income, expenses, debts, and financial goals. This will help you determine how much coverage you require and what type of policy is best for you.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Consider Your Budget
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              Life insurance premiums can vary significantly depending on the policy and provider. Consider your budget and determine how much you can afford to pay in premiums each month. Keep in mind that premiums will increase as you age, so it's essential to choose a policy that fits your budget now and in the future.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Evaluate Policy Features and Riders
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              Not all life insurance policies are created equal. Evaluate the features and riders offered by different policies, such as term length, convertibility options, accelerated death benefit, and waiver of premium. Consider what features are essential to you and your family, and choose a policy that offers the right combination of benefits.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
