import React from "react";

export const ContactAreaMap = () => {
  return (
    <div className="contact-map">
      <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.7182164260403!2d80.1677153!3d13.117029100000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526398deca9a51%3A0x37b76b18fa57ac9a!2sLakshmi%20Avenue%2C%20Hari%20Siva%20Flats!5e0!3m2!1sen!2sin!4v1725963303871!5m2!1sen!2sin" 
        allowfullscreen
        loading="lazy"
        title="contact map"
      ></iframe>
    </div>
  );
};
