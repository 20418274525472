import canvabanner from '../assets/img/banner/canvabanner.png'
import brochure from '../assets/img/logo/VR Policy Market_Introduction.pdf'
import brochure_health from '../assets/img/logo/Health Insurance_Brochure.pdf'
import brochure_home from '../assets/img/logo/Home Insurance_Brochure.pdf'
import brochure_corporate from '../assets/img/logo/Corporate Insurance_Brochure.pdf'
import brochure_life from '../assets/img/logo/Life Insurance_Brochure.pdf'
import brochure_motor from '../assets/img/logo/Motor Insurance_Brochure.pdf'
import brochure_travel from '../assets/img/logo/Travel Insurance_Brochure.pdf'
import family from '../assets/img/banner/family.png'
import logo from "../assets/img/logo/logo.jpeg";
import w_logo from "../assets/img/logo/w_logo.png";
import h2_footer_bg from "../assets/img/bg/h2_footer_bg.png";
import services_bg02 from "../assets/img/bg/services_bg02.jpg";
import h2_banner_bg from "../assets/img/banner/h2_banner_bg.jpg";
import h2_banner_img from "../assets/img/banner/h2_banner_img.png";
import h2_banner_shape01 from "../assets/img/banner/h2_banner_shape01.png";
import h2_banner_shape02 from "../assets/img/banner/h2_banner_shape02.png";
import h2_banner_shape03 from "../assets/img/banner/h2_banner_shape03.png";
import h3_banner_img01 from "../assets/img/banner/h3_banner_img01.jpg";
import h3_banner_img02 from "../assets/img/banner/h3_banner_img02.jpg";
import h3_banner_img03 from "../assets/img/banner/h3_banner_img03.jpg";
import h3_banner_shape01 from "../assets/img/banner/h3_banner_shape01.png";
import h3_banner_shape02 from "../assets/img/banner/h3_banner_shape02.png";
import h4_banner_img from "../assets/img/banner/h4_banner_img.png";
import h4_banner_shape01 from "../assets/img/banner/h4_banner_shape01.png";
import h4_banner_shape02 from "../assets/img/banner/h4_banner_shape02.png";
import h4_banner_shape03 from "../assets/img/banner/h4_banner_shape03.png";
import h5_banner_shape01 from "../assets/img/banner/h5_banner_shape01.png";
import h5_banner_shape02 from "../assets/img/banner/h5_banner_shape02.png";
import h5_banner_shape03 from "../assets/img/banner/h5_banner_shape03.png";
import banner_shape from "../assets/img/banner/banner_shape.png";
import h5_banner_main_img from "../assets/img/banner/h5_banner_main_img.png";
import h2_about_img01 from "../assets/img/images/h2_about_img01.jpg";
import h2_about_img02 from "../assets/img/images/h2_about_img02.jpg";
import h3_about_img01 from "../assets/img/images/h3_about_img01.jpg";
import h3_about_img02 from "../assets/img/images/h3_about_img02.jpg";
import h3_about_shape01 from "../assets/img/images/h3_about_shape01.png";
import h3_about_shape02 from "../assets/img/images/h3_about_shape02.png";
import h3_about_shape03 from "../assets/img/images/h3_about_shape03.png";
import about_author from "../assets/img/images/about_author.png";
import signature from "../assets/img/images/signature.png";
import testi_avatar01 from "../assets/img/images/testi_avatar01.png";
import testi_avatar02 from "../assets/img/images/testi_avatar02.png";
import h3_testimonial_img from "../assets/img/images/h3_testimonial_img.jpg";
import h4_testimonial_img from "../assets/img/images/h4_testimonial_img.png";
import h4_testimonial_img_shape from "../assets/img/images/h4_testimonial_img_shape.png";
import h5_testimonial_img from "../assets/img/images/h5_testimonial_img.png";
import h5_testimonial_shape01 from "../assets/img/images/h5_testimonial_shape01.png";
import h5_testimonial_shape02 from "../assets/img/images/h5_testimonial_shape02.png";
import h5_testimonial_shape03 from "../assets/img/images/h5_testimonial_shape03.png";
import h4_testimonial_shape01 from "../assets/img/images/h4_testimonial_shape01.png";
import h4_testimonial_shape02 from "../assets/img/images/h4_testimonial_shape02.png";
import h2_about_shape01 from "../assets/img/images/h2_about_shape01.png";
import h2_about_shape02 from "../assets/img/images/h2_about_shape02.png";
import h2_about_shape03 from "../assets/img/images/h2_about_shape03.png";
import h4_about_img01 from "../assets/img/images/h4_about_img01.jpeg"
import h4_about_img02 from "../assets/img/images/h4_about_img02.jpg";
import h5_about_img from "../assets/img/images/h5_about_img.png";
import h5_about_shape01 from "../assets/img/images/h5_about_shape01.png";
import h5_about_shape02 from "../assets/img/images/h5_about_shape02.png";
import h4_about_shape from "../assets/img/images/h4_about_shape.png";
import overview_img01 from "../assets/img/images/overview_img01.jpg";
import overview_img02 from "../assets/img/images/overview_img02.jpg";
import overview_img_shape from "../assets/img/images/overview_img_shape.png";
import h3_overview_img01 from "../assets/img/images/h3_overview_img01.jpg";
import h3_overview_img02 from "../assets/img/images/h3_overview_img02.jpg";
import h3_overview_shape01 from "../assets/img/images/h3_overview_shape01.png";
import h3_overview_shape02 from "../assets/img/images/h3_overview_shape02.png";
import request_shape from "../assets/img/images/request_shape.png";
import h2_request_shape01 from "../assets/img/images/h2_request_shape01.png";
import h2_request_shape02 from "../assets/img/images/h2_request_shape02.png";
import pricing_shape from "../assets/img/images/pricing_shape.png";
import choose_shape from "../assets/img/images/choose_shape.png";
import h4_choose_img from "../assets/img/images/h4_choose_img.jpeg";
import choose_img_shape01 from "../assets/img/images/choose_img_shape01.png";
import choose_img_shape02 from "../assets/img/images/choose_img_shape02.png";
import estimate_img from "../assets/img/images/estimate_img.png";
import counter_shape01 from "../assets/img/images/counter_shape01.png";
import counter_shape02 from "../assets/img/images/counter_shape02.png";
import brand_img01 from "../assets/img/brand/brand_img01.png";
import brand_img02 from "../assets/img/brand/brand_img02.png";
import brand_img03 from "../assets/img/brand/brand_img03.png";
import brand_img04 from "../assets/img/brand/brand_img04.png";
import brand_img05 from "../assets/img/brand/brand_img05.png";
import services_item_shape from "../assets/img/services/services_item_shape.png";
import h2_services_img01 from "../assets/img/services/h2_services_img01.jpg";
import h2_services_img02 from "../assets/img/services/h2_services_img02.jpg";
import h2_services_img03 from "../assets/img/services/h2_services_img03.jpg";
import h2_services_img04 from "../assets/img/services/h2_services_img04.jpg";

import h4_services_img01 from "../assets/img/services/h4_services_img01.jpeg";
import h4_services_img02 from "../assets/img/services/h4_services_img02.jpeg";
import h4_services_img03 from "../assets/img/services/h4_services_img03.jpeg";
import h4_services_img04 from "../assets/img/services/h4_services_img04.jpeg";
import h4_services_img05 from "../assets/img/services/h4_services_img05.jpeg";
import h4_services_img06 from "../assets/img/services/h4_services_img06.jpeg";

import h4_services_shape from "../assets/img/services/h4_services_shape.png";
import services_img01 from "../assets/img/services/services_img01.jpg";
import services_img02 from "../assets/img/services/services_img02.jpg";
import services_img03 from "../assets/img/services/services_img03.jpg";
import services_img04 from "../assets/img/services/services_img03.jpg";

import services_img05 from "../assets/img/services/services_img03.jpg";
import h2_project_img01 from "../assets/img/project/h2_project_img01.jpg";
import h2_project_img02 from "../assets/img/project/h2_project_img02.jpg";
import h2_project_img03 from "../assets/img/project/h2_project_img03.jpg";
import h3_project_img01 from "../assets/img/project/h3_project_img01.jpg";
import h3_project_img02 from "../assets/img/project/h3_project_img02.jpg";
import h3_project_img03 from "../assets/img/project/h3_project_img03.jpg";
import h3_project_img04 from "../assets/img/project/h3_project_img04.jpg";
import h5_project_img01 from "../assets/img/project/h5_project_img01.jpg";
import h5_project_img02 from "../assets/img/project/h5_project_img02.jpg";
import h5_project_img03 from "../assets/img/project/h5_project_img03.jpg";
import h5_project_img04 from "../assets/img/project/h5_project_img04.jpg";
import h5_project_img05 from "../assets/img/project/h5_project_img05.jpg";
import h2_team_img01 from "../assets/img/team/h2_team_img01.jpg";
import h2_team_img02 from "../assets/img/team/h2_team_img02.jpg";
import h2_team_img03 from "../assets/img/team/h2_team_img03.jpg";
import h2_team_img04 from "../assets/img/team/h2_team_img04.jpg";
import team_shape from "../assets/img/team/team_shape.png";
import h3_team_img01 from "../assets/img/team/h3_team_img01.png";
import h3_team_img02 from "../assets/img/team/h3_team_img02.png";
import h3_team_img03 from "../assets/img/team/h3_team_img03.png";
import h3_team_img04 from "../assets/img/team/h3_team_img04.png";
import h4_team_img01 from "../assets/img/team/h4_team_img01.jpg";
import h4_team_img02 from "../assets/img/team/h4_team_img02.jpg";
import h4_team_img03 from "../assets/img/team/h4_team_img03.jpg";
import h4_team_img04 from "../assets/img/team/h4_team_img04.jpg";
import h5_team_img01 from "../assets/img/team/h5_team_img01.png";
import h5_team_img02 from "../assets/img/team/h5_team_img02.png";
import h5_team_img03 from "../assets/img/team/h5_team_img03.png";
import h5_team_img04 from "../assets/img/team/h5_team_img04.png";
import check_icon from "../assets/img/icons/check_icon.svg";
import check_icon02 from "../assets/img/icons/check_icon02.svg";
import check_icon03 from "../assets/img/icons/check_icon03.svg";
import right_arrow from "../assets/img/icons/right-arrow.svg";
import quote02 from "../assets/img/icons/quote02.svg";
import quote from "../assets/img/icons/quote.svg";
import plus_icon from "../assets/img/icons/plus_icon.svg";
import rating from "../assets/img/icons/rating.svg";
import avatar from "../assets/img/blog/avatar.png";
import blog_avatar01 from "../assets/img/blog/blog_avatar01.png";
import blog_avatar02 from "../assets/img/blog/blog_avatar02.png";
import blog_avatar03 from "../assets/img/blog/blog_avatar03.png";
import blog_avatar04 from "../assets/img/blog/blog_avatar04.png";
import blog_avatar05 from "../assets/img/blog/blog_avatar05.png";
import blog_avatar06 from "../assets/img/blog/blog_avatar06.png";
import h2_blog_img01 from "../assets/img/blog/h2_blog_img01.jpg";
import h2_blog_img02 from "../assets/img/blog/h2_blog_img02.jpg";
import h2_blog_img03 from "../assets/img/blog/h2_blog_img03.jpg";
import h3_blog_img01 from "../assets/img/blog/h3_blog_img01.jpg";
import h3_blog_img02 from "../assets/img/blog/h3_blog_img02.jpg";
import h3_blog_img03 from "../assets/img/blog/h3_blog_img03.jpg";
import h3_blog_img04 from "../assets/img/blog/h3_blog_img04.jpg";
import h3_blog_img05 from "../assets/img/blog/h3_blog_img05.jpg";
import h3_blog_img06 from "../assets/img/blog/h3_blog_img06.jpg";
import h4_blog_img01 from "../assets/img/blog/h4_blog_img01.jpeg";
import h4_blog_img02 from "../assets/img/blog/h4_blog_img02.jpeg";
import h4_blog_img03 from "../assets/img/blog/h4_blog_img03.jpeg";
import about_img01 from "../assets/img/images/about_img01.png";
import about_img02 from "../assets/img/images/about_img02.jpg";
import about_img03 from "../assets/img/images/about_img03.jpg";
import about_img_shape01 from "../assets/img/images/about_img_shape01.png";
import about_img_shape02 from "../assets/img/images/about_img_shape02.png";
import about_shape01 from "../assets/img/images/about_shape01.png";
import about_shape02 from "../assets/img/images/about_shape02.png";
import project_img01 from "../assets/img/project/project_img01.jpg";
import project_img02 from "../assets/img/project/project_img02.jpg";
import project_img03 from "../assets/img/project/project_img03.jpg";
import faq_shape02 from "../assets/img/images/faq_shape02.png";
import faq_shape03 from "../assets/img/images/faq_shape03.png";
import faq_img01 from "../assets/img/images/faq_img01.jpg";
import faq_img02 from "../assets/img/images/faq_img02.jpg";
import team_img01 from "../assets/img/team/team_img01.jpg";
import team_img02 from "../assets/img/team/team_img02.jpg";
import team_img03 from "../assets/img/team/team_img03.jpg";
import team_img04 from "../assets/img/team/team_img04.jpg";
import testimonial_img from "../assets/img/images/testimonial_img.jpg";
import contact_shape from "../assets/img/images/contact_shape.png";
import blog_img01 from "../assets/img/blog/blog_img01.jpg";
import blog_img02 from "../assets/img/blog/blog_img02.jpg";
import blog_img03 from "../assets/img/blog/blog_img03.jpg";
import breadcrumb_shape01 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape02 from "../assets/img/images/breadcrumb_shape02.png";
import inner_about_img01 from "../assets/img/images/inner_about_img01.jpg";
import inner_about_img02 from "../assets/img/images/inner_about_img02.jpg";
import inner_about_img03 from "../assets/img/images/inner_about_img03.jpg";
import inner_about_img04 from "../assets/img/images/inner_about_img04.jpg";
import inner_about_img05 from "../assets/img/images/inner_about_img05.png";
import inner_about_shape01 from "../assets/img/images/inner_about_shape01.png";
import inner_about_shape02 from "../assets/img/images/inner_about_shape02.png";
import inner_about_shape03 from "../assets/img/images/inner_about_shape03.png";
import features_shape01 from "../assets/img/images/features_shape01.png";
import features_shape02 from "../assets/img/images/features_shape02.png";
import inner_choose_img from "../assets/img/images/inner_choose_img.jpg";
import inner_counter_shape from "../assets/img/images/inner_counter_shape.png";
import inner_features_shape from "../assets/img/images/inner_features_shape.png";
import services_details01 from "../assets/img/services/services_details01.jpeg";
import services_details02 from "../assets/img/services/services_details02.jpeg";
import services_details03 from "../assets/img/services/services_details03.jpeg";
import services_details04 from "../assets/img/services/services_details04.jpeg";
import services_details05 from "../assets/img/services/services_details05.jpeg";

import services_details06 from "../assets/img/services/services_details06.jpeg";
import sd_video_img from "../assets/img/services/sd_video_img.jpeg";
import sd_working_img01 from "../assets/img/services/sd_working_img01.jpg";
import sd_working_img02 from "../assets/img/services/sd_working_img02.jpg";
import sd_working_img03 from "../assets/img/services/sd_working_img03.jpg";
import sd_working_img04 from "../assets/img/services/sd_working_img04.jpeg";
import project_details01 from "../assets/img/project/project_details01.jpg";
import project_details02 from "../assets/img/project/project_details02.jpg";
import share from "../assets/img/icons/share.svg";
import team_details_img from "../assets/img/team/team_details_img.jpg";
import rc_post01 from "../assets/img/blog/rc_post01.jpg";
import rc_post02 from "../assets/img/blog/rc_post02.jpg";
import rc_post03 from "../assets/img/blog/rc_post03.jpg";
import rc_post04 from "../assets/img/blog/rc_post04.jpg";
import blog_details01 from "../assets/img/blog/blog-details01.jpeg";
import blog_details02 from "../assets/img/blog/blog-details02.jpeg";
import comment01 from "../assets/img/blog/comment01.png";
import comment02 from "../assets/img/blog/comment02.png";
import contact_img from "../assets/img/images/contact_img.jpeg";

// logo
export const LOGO = logo;
export const W_LOGO = w_logo;
export const CanvaBanner = canvabanner;
export const Family = family;
export const Brochure = brochure;
export const Brochure_Health = brochure_health;
export const brochure_Home = brochure_home;
export const Brochure_Motor = brochure_motor;
export const Brochure_Corporate = brochure_corporate;
export const Brochure_Life = brochure_life;
export const Brochure_Travel = brochure_travel;



// bg
export const H2_FOOTER_BG = h2_footer_bg;
export const SERVICES_BG02 = services_bg02;

// banner
export const H2_BANNER_BG = h2_banner_bg;
export const H2_BANNER_IMG = h2_banner_img;
export const H2_BANNER_SHAPE01 = h2_banner_shape01;
export const H2_BANNER_SHAPE02 = h2_banner_shape02;
export const H2_BANNER_SHAPE03 = h2_banner_shape03;
export const H3_BANNER_IMG01 = h3_banner_img01;
export const H3_BANNER_IMG02 = h3_banner_img02;
export const H3_BANNER_IMG03 = h3_banner_img03;
export const H3_BANNER_SHAPE01 = h3_banner_shape01;
export const H3_BANNER_SHAPE02 = h3_banner_shape02;
export const H4_BANNER_IMG = h4_banner_img;
export const H4_BANNER_SHAPE01 = h4_banner_shape01;
export const H4_BANNER_SHAPE02 = h4_banner_shape02;
export const H4_BANNER_SHAPE03 = h4_banner_shape03;
export const H5_BANNER_SHAPE01 = h5_banner_shape01;
export const H5_BANNER_SHAPE02 = h5_banner_shape02;
export const H5_BANNER_SHAPE03 = h5_banner_shape03;
export const H5_BANNER_MAIN_IMG = h5_banner_main_img;
export const BANNER_SHAPE = banner_shape;

// images
export const H2_ABOUT_IMG01 = h2_about_img01;
export const H2_ABOUT_IMG02 = h2_about_img02;
export const ABOUT_AUTHOR = about_author;
export const SIGNATURE = signature;
export const H2_ABOUT_SHAPE01 = h2_about_shape01;
export const H2_ABOUT_SHAPE02 = h2_about_shape02;
export const H2_ABOUT_SHAPE03 = h2_about_shape03;
export const OVERVIEW_IMG01 = overview_img01;
export const OVERVIEW_IMG02 = overview_img02;
export const OVERVIEW_IMG_SHAPE = overview_img_shape;
export const CHOOSE_SHAPE = choose_shape;
export const H4_CHOOSE_IMG = h4_choose_img;
export const CHOOSE_IMG_SHAPE01 = choose_img_shape01;
export const CHOOSE_IMG_SHAPE02 = choose_img_shape02;
export const TESTI_AVATAR01 = testi_avatar01;
export const TESTI_AVATAR02 = testi_avatar02;
export const H3_TESTIMONIAL_IMG = h3_testimonial_img;
export const PRICING_SHAPE = pricing_shape;
export const REQUEST_SHAPE = request_shape;
export const H2_REQUEST_SHAPE01 = h2_request_shape01;
export const H2_REQUEST_SHAPE02 = h2_request_shape02;
export const H3_ABOUT_IMG01 = h3_about_img01;
export const H3_ABOUT_IMG02 = h3_about_img02;
export const H3_ABOUT_SHAPE01 = h3_about_shape01;
export const H3_ABOUT_SHAPE02 = h3_about_shape02;
export const H3_ABOUT_SHAPE03 = h3_about_shape03;
export const H4_ABOUT_IMG01 = h4_about_img01;
export const H4_ABOUT_IMG02 = h4_about_img02;
export const H4_ABOUT_SHAPE = h4_about_shape;
export const H5_ABOUT_IMG = h5_about_img;
export const H5_ABOUT_SHAPE01 = h5_about_shape01;
export const H5_ABOUT_SHAPE02 = h5_about_shape02;
export const H3_OVERVIEW_IMG01 = h3_overview_img01;
export const H3_OVERVIEW_IMG02 = h3_overview_img02;
export const H3_OVERVIEW_SHAPE01 = h3_overview_shape01;
export const H3_OVERVIEW_SHAPE02 = h3_overview_shape02;
export const ESTIMATE_TIME = estimate_img;
export const H4_TESTIMONIAL_IMG = h4_testimonial_img;
export const H4_TESTIMONIAL_IMG_SHAPE = h4_testimonial_img_shape;
export const H4_TESTIMONIAL_SHAPE01 = h4_testimonial_shape01;
export const H4_TESTIMONIAL_SHAPE02 = h4_testimonial_shape02;
export const H5_TESTIMONIAL_IMG = h5_testimonial_img;
export const H5_TESTIMONIAL_SHAPE01 = h5_testimonial_shape01;
export const H5_TESTIMONIAL_SHAPE02 = h5_testimonial_shape02;
export const H5_TESTIMONIAL_SHAPE03 = h5_testimonial_shape03;
export const ABOUT_IMG01 = about_img01;
export const ABOUT_IMG02 = about_img02;
export const ABOUT_IMG03 = about_img03;
export const ABOUT_IMG_SHAPE01 = about_img_shape01;
export const ABOUT_IMG_SHAPE02 = about_img_shape02;
export const ABOUT_SHAPE01 = about_shape01;
export const ABOUT_SHAPE02 = about_shape02;
export const COUNTER_SHAPE01 = counter_shape01;
export const COUNTER_SHAPE02 = counter_shape02;
export const FAQ_SHAPE02 = faq_shape02;
export const FAQ_SHAPE03 = faq_shape03;
export const FAQ_IMG01 = faq_img01;
export const FAQ_IMG02 = faq_img02;
export const TESTIMONIAL_IMG = testimonial_img;
export const CONTACT_SHAPE = contact_shape;
export const BREADCRUMB_SHAPE01 = breadcrumb_shape01;
export const BREADCRUMB_SHAPE02 = breadcrumb_shape02;
export const INNER_ABOUT_IMG01 = inner_about_img01;
export const INNER_ABOUT_IMG02 = inner_about_img02;
export const INNER_ABOUT_IMG03 = inner_about_img03;
export const INNER_ABOUT_IMG04 = inner_about_img04;
export const INNER_ABOUT_IMG05 = inner_about_img05;
export const INNER_ABOUT_SHAPE01 = inner_about_shape01;
export const INNER_ABOUT_SHAPE02 = inner_about_shape02;
export const INNER_ABOUT_SHAPE03 = inner_about_shape03;
export const FEATURES_SHAPE01 = features_shape01;
export const FEATURES_SHAPE02 = features_shape02;
export const INNER_CHOOSE_IMG = inner_choose_img;
export const INNER_COUNTER_SHAPE = inner_counter_shape;
export const INNER_FEATURES_SHAPE = inner_features_shape;
export const SERVICES_DETAILS01 = services_details01;
export const SERVICES_DETAILS02 = services_details02;
export const SERVICES_DETAILS03 = services_details03;
export const SERVICES_DETAILS04 = services_details04;
export const SERVICES_DETAILS05 = services_details05;
export const SERVICES_DETAILS06 = services_details06;


export const SD_VIDEO_IMG = sd_video_img;
export const SD_WORKING_IMG01 = sd_working_img01;
export const SD_WORKING_IMG02 = sd_working_img02;
export const SD_WORKING_IMG03 = sd_working_img03;
export const SD_WORKING_IMG04 = sd_working_img04;
export const PROJECT_DETAILS01 = project_details01;
export const PROJECT_DETAILS02 = project_details02;
export const CONTACT_IMG = contact_img;

// brand
export const BRAND_IMG01 = brand_img01;
export const BRAND_IMG02 = brand_img02;
export const BRAND_IMG03 = brand_img03;
export const BRAND_IMG04 = brand_img04;
export const BRAND_IMG05 = brand_img05;

// services
export const SERVICES_ITEM_SHAPE = services_item_shape;
export const H2_SERVICES_IMG01 = h2_services_img01;
export const H2_SERVICES_IMG02 = h2_services_img02;
export const H2_SERVICES_IMG03 = h2_services_img03;
export const H2_SERVICES_IMG04 = h2_services_img04;
export const H4_SERVICES_SHAPE = h4_services_shape;
export const H4_SERVICES_IMG01 = h4_services_img01;
export const H4_SERVICES_IMG02 = h4_services_img02;
export const H4_SERVICES_IMG03 = h4_services_img03;
export const H4_SERVICES_IMG04 = h4_services_img04;
export const H4_SERVICES_IMG05 = h4_services_img05;
export const H4_SERVICES_IMG06 = h4_services_img06;

export const SERVICES_IMG01 = services_img01;
export const SERVICES_IMG02 = services_img02;
export const SERVICES_IMG03 = services_img03;
export const SERVICES_IMG04 = services_img04;
export const SERVICES_IMG05 = services_img05;

// project
export const H2_PROJECT_IMG01 = h2_project_img01;
export const H2_PROJECT_IMG02 = h2_project_img02;
export const H2_PROJECT_IMG03 = h2_project_img03;
export const H3_PROJECT_IMG01 = h3_project_img01;
export const H3_PROJECT_IMG02 = h3_project_img02;
export const H3_PROJECT_IMG03 = h3_project_img03;
export const H3_PROJECT_IMG04 = h3_project_img04;
export const H5_PROJECT_IMG01 = h5_project_img01;
export const H5_PROJECT_IMG02 = h5_project_img02;
export const H5_PROJECT_IMG03 = h5_project_img03;
export const H5_PROJECT_IMG04 = h5_project_img04;
export const H5_PROJECT_IMG05 = h5_project_img05;
export const PROJECT_IMG01 = project_img01;
export const PROJECT_IMG02 = project_img02;
export const PROJECT_IMG03 = project_img03;

// team
export const H2_TEAM_IMG01 = h2_team_img01;
export const H2_TEAM_IMG02 = h2_team_img02;
export const H2_TEAM_IMG03 = h2_team_img03;
export const H2_TEAM_IMG04 = h2_team_img04;
export const TEAM_SHAPE = team_shape;
export const H3_TEAM_IMG01 = h3_team_img01;
export const H3_TEAM_IMG02 = h3_team_img02;
export const H3_TEAM_IMG03 = h3_team_img03;
export const H3_TEAM_IMG04 = h3_team_img04;
export const H4_TEAM_IMG01 = h4_team_img01;
export const H4_TEAM_IMG02 = h4_team_img02;
export const H4_TEAM_IMG03 = h4_team_img03;
export const H4_TEAM_IMG04 = h4_team_img04;
export const H5_TEAM_IMG01 = h5_team_img01;
export const H5_TEAM_IMG02 = h5_team_img02;
export const H5_TEAM_IMG03 = h5_team_img03;
export const H5_TEAM_IMG04 = h5_team_img04;
export const TEAM_IMG01 = team_img01;
export const TEAM_IMG02 = team_img02;
export const TEAM_IMG03 = team_img03;
export const TEAM_IMG04 = team_img04;
export const TEAM_DETAILS_IMG = team_details_img;

// icons
export const CHECK_ICON = check_icon;
export const CHECK_ICON02 = check_icon02;
export const CHECK_ICON03 = check_icon03;
export const RIGHT_ARROW = right_arrow;
export const QUOTE02 = quote02;
export const QUOTE = quote;
export const PLUS_ICON = plus_icon;
export const RATING = rating;
export const SHARE = share;

// blogs
export const AVATAR = avatar;
export const BLOG_AVATAR01 = blog_avatar01;
export const BLOG_AVATAR02 = blog_avatar02;
export const BLOG_AVATAR03 = blog_avatar03;
export const BLOG_AVATAR04 = blog_avatar04;
export const BLOG_AVATAR05 = blog_avatar05;
export const BLOG_AVATAR06 = blog_avatar06;
export const H2_BLOG_IMG01 = h2_blog_img01;
export const H2_BLOG_IMG02 = h2_blog_img02;
export const H2_BLOG_IMG03 = h2_blog_img03;
export const H3_BLOG_IMG01 = h3_blog_img01;
export const H3_BLOG_IMG02 = h3_blog_img02;
export const H3_BLOG_IMG03 = h3_blog_img03;
export const H3_BLOG_IMG04 = h3_blog_img04;
export const H3_BLOG_IMG05 = h3_blog_img05;
export const H3_BLOG_IMG06 = h3_blog_img06;
export const H4_BLOG_IMG01 = h4_blog_img01;
export const H4_BLOG_IMG02 = h4_blog_img02;
export const H4_BLOG_IMG03 = h4_blog_img03;
export const BLOG_IMG01 = blog_img01;
export const BLOG_IMG02 = blog_img02;
export const BLOG_IMG03 = blog_img03;
export const RC_POST01 = rc_post01;
export const RC_POST02 = rc_post02;
export const RC_POST03 = rc_post03;
export const RC_POST04 = rc_post04;
export const BLOG_DETAILS01 = blog_details01;
export const BLOG_DETAILS02 = blog_details02;
export const COMMENT01 = comment01;
export const COMMENT02 = comment02;
