import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SD_WORKING_IMG01,
  SD_WORKING_IMG02,
  SERVICES_DETAILS03,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";

const ServicesDetailsPageThree = () => {
  const InsuranceType = "Travel"
  return (
    <Layout breadcrumb={"Services"} title={"Travel Insurance"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper InsuranceType={InsuranceType}>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="" />
          </div>
          <div className="services-details-content">
            <h2 className="title">Explore the world, protected from the unexpected!</h2>
            <p>
            Get ready for the adventure of a lifetime! But before you take off, make sure you're protected with travel insurance! This incredible safeguard ensures your trip is filled with excitement, not unexpected expenses! With travel insurance, you can explore the world with confidence and peace of mind! Say goodbye to travel worries and hello to unforgettable memories!"</p>
            <div className="sd-inner-wrap sd-inner-wrap-three">
              <div className="row align-items-center">
                <div className="col-43">
                  <div className="services-details-list-two">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-piggy-bank"></i>
                        </div>
                        <div className="content">
                          <h5 className="title"> Emergency Assistance:</h5>
                          <p>
                          24/7 Emergency Assistance for help whenever you need it
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-calculator"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Travel Inconvenience Coverage:</h5>
                          <p>
                          Travel Inconvenience Coverage for flight delays, missed connections, and more
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-57">
                  <div className="content">
                    <h3 className="title-two">
                      Benefits of Travel Insurance
                    </h3>
                    <p>
                    Travel insurance protects your trip from unexpected events,
                    Providing financial security and peace of mind that never ends.
                    It covers medical emergencies, trip cancellations, and more,
                    Giving you the freedom to travel with confidence galore.       
                    Travel insurance is a safeguard for your journey.
Travel insurance also provides 24/7 emergency assistance,
Helping you navigate unfamiliar places with confidence.
With travel insurance, you can explore the world with peace of mind.
                                 </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Travel Assistance
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Luggage Protection
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Emergency Evacuation
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Happy Customers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="industrial-working-wrap">
              <h2 className="title-two">Debunking Common Travel Insurance Myths</h2>
              <p className="info-one">
              Travel insurance is often misunderstood, and many travelers embark on their journeys without proper coverage due to misconceptions about its benefits and limitations. Let's debunk some common travel insurance myths to help you make informed decisions for your next adventure.
              </p>
            {/*  <div className="row">
                <div className="col-md-6">
                  <img src={SD_WORKING_IMG01} alt="" />
                </div>
                <div className="col-md-6">
                  <img src={SD_WORKING_IMG02} alt="" />
                </div>
              </div>*/}
              <p className="info-two">
              <b>Myth 1:</b> Travel insurance is unnecessary if I have health insurance.

              <b>Reality:</b> Health insurance may not cover you abroad, and travel insurance provides additional benefits like trip cancellations, delays, and lost luggage.
              </p>
              <p></p>

              <p>
              <b>Myth 2:</b>  Travel insurance is too expensive.

<b>Reality:</b> Premiums are often a small fraction of your trip cost, and the potential financial losses without insurance far outweigh the cost.
</p>
<p></p>

              <p>
              <b>Myth 3:</b>   Travel insurance only covers medical emergencies.

<b>Reality:</b> Travel insurance also covers trip-related issues like cancellations, interruptions, delays, and lost or stolen belongings.
</p>
<p></p>

              <p>
              <b>Myth 4:</b> I can buy travel insurance at any time before my trip.

<b>Reality:</b>Buying insurance early can provide additional benefits like trip cancellation coverage, which typically requires purchase within a specific timeframe.
</p>
<p></p>

              <p>
              <b>Myth :5</b>   Travel insurance covers everything.

<b>Reality:</b> Policies have exclusions and limitations, so carefully review your policy to understand what's covered and what's not.
</p>
<p></p>

              <p>
              <b>Myth 6:</b> : Travel insurance is only for international travel. 

<b>Reality:</b> Domestic travel insurance can also provide valuable protection against trip-related disruptions and losses.
</p>

<p>

</p>
<p></p>
<p>By understanding the facts, you can make informed decisions about travel insurance and enjoy your journey with peace of mind.</p>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    {/*  <BrandSeven />*/}
    </Layout>
  );
};

export default ServicesDetailsPageThree;
