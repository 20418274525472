import React from "react";
import { AVATAR } from "../../lib/assets";

export const BlogAuthorInfo = () => {
  return (
    <div className="blog-avatar-wrap mb-65">
     {/* {/*  <div className="blog-avatar-img">
       <a href="#">
          <img src={AVATAR} alt="img" />
        </a> 
      </div>*/}

      <div className="blog-avatar-info">
        <span className="designation">Author</span>
        <h4 className="name">
          <a href="#">Nithya</a>
        </h4>

        <p>
         Nithya is an insurance expert with 10+ years of experience. He helps individuals and businesses navigate insurance and makes complex concepts accessible through his writing
        </p>
      </div>
    </div>
  );
};
