import React from "react";
import { Layout } from "../../layouts/Layout";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";
import {ServicesDetailsVehicleFaq} from '../../components/ServicesDetails/ServicesDetailsVehicleFaq'
import { CHECK_ICON, SD_VIDEO_IMG, SERVICES_DETAILS01 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { BrandSeven } from "../../components/Brand/BrandSeven";

const ServicesDetailsPage = () => {
  const InsuranceType = "Motor"

  return (
    <Layout breadcrumb={"Services"} title={"Motor Insurance"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper hideContact hideTitle  InsuranceType={InsuranceType}>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS01} alt="" />
          </div>

          <div className="services-details-content">
            <h2 className="title">
                 What is Motor Insurance?
            </h2>
            <p>
            Motor insurance is a type of insurance policy that provides financial protection to vehicle owners against various types of losses or damages that may occur to their vehicle. This can include accidents or collisions with other vehicles or objects, theft or vandalism, natural disasters like floods, fires, or hail, and liability for damages or injuries caused to others.            </p>

            <p>
            By purchasing Motor insurance, vehicle owners can avoid costly repairs and medical bills, protect their financial assets in case of a lawsuit, and meet legal requirements for insurance in their state or country. Additionally, Motor insurance provides peace of mind while driving, knowing that you are protected against unexpected events. </p>

            <div className="sd-inner-wrap">
              <div className="row align-items-center">
                <div className="col-56">
                  <div className="content">
                    <h3 className="title-two">
                    A Motor insurance policy typically covers the following:

                    </h3>
                    <p>
                      
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Third-party liability
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Own damage
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Personal accident
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Comprehensive coverage
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-44">
                  <div className="thumb">
                    <img src={SD_VIDEO_IMG} alt="" />

                  {/*  <VideoPlayerOne
                      src={"https://www.youtube.com/watch?v=6mkoGSqTqFI"}
                    />*/}
                  </div>
                </div>
              </div>
            </div>

            <p>
            <b>Third-party liability:</b> Covers damages or injuries caused to others in an accident. </p>
            <p><b>Own damage:</b> Covers damages to your own vehicle in an accident or due to other covered events.</p>
            <p><b>Personal accident:</b> Covers medical expenses for you and your passengers. </p>
            <p><b>Comprehensive coverage:</b>Covers damages due to non-accident events like theft, vandalism, or natural disasters </p>


            <div className="company-benefit-wrap">
              <h2 className="title-two">By purchasing Motor insurance, you will have: </h2>
              <p>
              </p>

              {/* faq accordion */}
              <ServicesDetailsVehicleFaq />
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    {/*  <BrandSeven />*/}

    </Layout>
  );
};

export default ServicesDetailsPage;
