import React, {createContext,useEffect, useState } from "react";
export const ValueContext = createContext();


export const EstimateForm = ({tab}) => {
  const [range, setRange] = useState(400000);
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [insuranceType, setInsurancetype] = useState("");

  useEffect(() => {
    setInsurancetype(tab);
  }, [tab]);

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('entry.2005620554', Name); 
    formData.append('entry.1045781291', Email); 
    formData.append('entry.1166974658', phone); 
    formData.append('entry.839337160', message); 
    formData.append('entry.720435743',range)
    formData.append('entry.352411605', insuranceType); 

    fetch('https://docs.google.com/forms/d/e/1FAIpQLSct5y1BPg23Rk-VuY2HX3sb1PCKUvV7cYvqWvz5YIyGENC5Dw/formResponse', {
      method: 'POST',
      body: formData,
      mode: 'no-cors'
    })
    .then(() => { alert('Form submitted successfully')
      // Clear form fields if necessary
      setName('');
      setEmail('');
      setPhone('');
      setRange(400000);
    })
    
    .catch((error) => alert('Error submitting form: ' + error));
  };

  return (
    
    <div className="estimate-form-wrap">
    <ValueContext.Provider insuranceType={{ insuranceType, setInsurancetype }}>
      {tab}
    </ValueContext.Provider> 
      <form onSubmit={handleSubmit}>
        <div className="form-grp">
          <input
            type="text"
            placeholder="Full Name"
            value={Name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-grp">
          <input
            type="email"
            placeholder="E-mail"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-grp">
          <input
            type="tel"
            placeholder="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="range-slider-wrap">
          <div className="content-top">
            <p>Your Budget:</p>
            <span>
              ₹<strong id="rangeValue">{range}</strong>
            </span>
          </div>

          <input
            className="range"
            type="range"
            value={range}
            min="0"
            max="600000"
            onChange={(e) => setRange(e.target.value)}
          />
        </div>

        <button className="btn btn-three" type="submit">
          Get a Quote Now
        </button>
      </form>
    </div>
  );
};



{/*import React, { useState } from "react";

export const EstimateForm = () => {
  const [range, setRange] = useState(400000);

  return (
    <div className="estimate-form-wrap">
      <form action="#">
        <div className="form-grp">
          <input type="text" placeholder="Full Name" />
        </div>
        <div className="form-grp">
          <input type="email" placeholder="E-mail" />
        </div>
        <div className="form-grp">
          <input type="number" placeholder="Phone" />
        </div>
        <div className="range-slider-wrap">
          <div className="content-top">
            <p>Your Budget:</p>
            <span>
            ₹<strong id="rangeValue">{range}</strong>
            </span>
          </div>

          <input
            className="range"
            type="range"
            value={range}
            min="0"
            max="600000"
            onChange={(e) => setRange(e.target.value)}
            onMouseMove={(e) => setRange(e.target.value)}
          />
        </div>

        <button className="btn btn-three" type="submit">
          Get a Quote Now
        </button>
      </form>
    </div>
  );
};


*/}