import React from "react";
import { Layout } from "../../layouts/Layout";
import { CHECK_ICON, SERVICES_DETAILS06 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";

const ServicesDetailsPageSix = () => {
  const InsuranceType = "Home"
  return (
    <Layout breadcrumb={"Services"} title={"Home Insurance"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper hideTitle InsuranceType={InsuranceType}>
        <div className="services-details-wrap">
          <div className="services-details-content">
            <h2 className="title">Home Insurance: Protecting Your Dream Home</h2>
            <p>
            
            Home insurance, also referred to as homeowners insurance, is a crucial form of property insurance that offers financial protection to homeowners. This protection shields homeowners from losses and damages to their home and assets, providing a sense of security and peace of mind. Home insurance is essential for homeowners, as it helps to minimize the risks associated with owning a home, such as natural disasters, theft, and accidents. </p>
            <div className="services-details-thumb-two">
              <img src={SERVICES_DETAILS06} alt="" />
            </div>
            <p>
            By investing in home insurance, homeowners can safeguard their financial well-being and protect their assets. Home insurance policies provide coverage for damages to the physical structure of the home, as well as personal belongings and contents within the home. Additionally, home insurance offers liability coverage, which protects homeowners against lawsuits and financial losses in case of accidents or injuries on their property. With home insurance, homeowners can enjoy the benefits of owning a home without the worry of unexpected events causing financial hardship.            </p>
            <div className="speed-optimized-wrap">
              <h4 className="title-two">Benefits</h4>
              <p>
              Home insurance provides financial protection and peace of mind to homeowners, safeguarding them against losses and damages to their home and assets. It also offers liability coverage, protecting homeowners against lawsuits and financial losses in case of accidents or injuries on their property.              </p>
            </div>
            <div className="sd-inner-wrap-five">
              <div className="row">
                <div className="col-50">
                  <div className="left-side-content">
                    <h4 className="title-two"> Types of Home Insrance</h4>
                    <p>Home insurance comes in various forms to cater to different needs and risks. Understanding the different types of home insurance can help you choose the right policy for your home and assets.  </p>
                  </div>
                </div>
                <div className="col-50">
                  <div className="right-side-content">
                   {/* <h4 className="title-two">Types of Home Insurance:
                    </h4>
                    <p>when an unknown printer took a galley type</p> */}
                    <p></p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Building Insurance
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Flood Insurance
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Earthquake Insurance
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Contents/Belongings Insurance
                      </li>
                    </ul>
                   
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="company-benefit-wrap">
              <h4 className="title-two">Home sweet home, insured and secure</h4>
              <p>
              Home insurance is an essential investment for homeowners, providing financial protection, peace of mind, and asset protection. With various types of home insurance policies available, homeowners can choose the one that best suits their needs. Don't wait until it's too late, invest in home insurance today and protect your dream home!              </p>

              {/* faq */}
             {/* <ServicesDetailsFaq /> */}
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    {/*  <BrandSeven /> */}
    </Layout>
  );
};

export default ServicesDetailsPageSix;
