import React from "react";
import { W_LOGO } from "../../lib/assets";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";


export const FooterThree = () => {
  return (
    <footer>
      <div className="footer-area-three">
        <div className="footer-top-three">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-7">
                <div className="footer-widget">
                  <div className="fw-logo">
                    <Link to="/">
                 {/*     <img src={W_LOGO} alt="" />*/}
                    </Link>
                  </div>
                  <div className="footer-content">
                    <p>
                      When an unknown printer took a galley of type aawer awtnd
                      scrambled it to make a type specimen book.
                    </p>
                    <div className="footer-social footer-social-three">
                      <ul className="list-wrap">
                        <li>
                          <a href="https://www.facebook.com/people/VR-Policy-Market/61565985988741/?mibextid=ZbWKwL">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/vrpolicymarket?igsh=MW1heWZjZ2xzaTdyOQ==">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/@vrpolicymarket">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Menu</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/about-4">Company</Link>
                      </li>
                      <li>
                        <Link to="/services-4">Our Services</Link>
                      </li>
                   {/*   <li>
                        <Link to="/contact">Press media</Link>
                      </li>*/}
                      <li>
                        <Link to="/blog">Our Blog</Link>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Quick Links</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                    <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                      <li>
                      <HashLink to="/#testimonialsection" >
                      Testimonials
                          </HashLink>
                      </li>
                      <li>
                        <HashLink to="/#EstimateAreaOneForm">Get A Quote</HashLink>
                      </li>
                    {/*  <li>
                        <Link to="/contact">Partners</Link>
                      </li>*/}
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                    {/*  <li>
                        <Link to="/contact">Pricing</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-7">
                <div className="footer-widget">
                  <h4 className="fw-title">Contact</h4>
                  <div className="footer-info">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-pin"></i>
                        </div>
                        <div className="content">
                          <p>No 4, Lakshmi Avenue, Block 4 F3, 1st Floor, Gandhi 1st Street, Gnanamurthy Nagar, Ambattur, Chennai 600053</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-phone-call"></i>
                        </div>
                        <div className="content">
                        <div className="phone_number">

                          <a href="tel: +91 79931 67897"> +91 79931 67897</a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                        <div className="phone_number">
                        <i className="flaticon-mail"></i>

                    {/*    <i className="fas fa-phone invert-icon" ></i>
                        <i className="fab fa-whatsapp"></i> */}
                        </div>
                        </div>
                        <div className="content">
                        <div className="phone_number">


                        <a href="mailto:support@vrpolicymarket.com">support@vrpolicymarket.com </a>
                          
                          </div>


                        </div>
                      </li>
                    
                      <li>
                        <div className="icon">
                          <i className="flaticon-clock"></i>
                        </div>
                        <div className="content">
                          <p>
                            Mon – Sun : 24/7 Support
                          
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-three">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text-two text-center">
                  <p>Copyright © Ratoon Infotech | All Right Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
