import React from "react";
import { ServicesDetailsSidebarCorporate } from "./ServicesDetailsSidebarCorporate";
import { ServicesDetailsSidebarHealth } from "./ServicesDetailsSidebarHealth";
import { ServicesDetailsSidebarHome } from "./ServicesDetailsSidebarHome";
import { ServicesDetailsSidebarLife } from "./ServicesDetailsSidebarLife";
import { ServicesDetailsSidebarMotor } from "./ServicesDetailsSidebarMotor";
import { ServicesDetailsSidebar } from "./ServicesDetailsSidebar";

export const ServicesDetailsWrapper = ({
  children,
  hideContact = false,
  hideTitle = false,
  InsuranceType
}) => {
  const renderSidebar = () => {
    switch (InsuranceType) {
      case "Health":
        return (
          <ServicesDetailsSidebarHealth
            hideTitle={hideTitle}
            hideContact={hideContact}
          />
        );
      case "Home":
        return (
          <ServicesDetailsSidebarHome
            hideTitle={hideTitle}
            hideContact={hideContact}
          />
        );
      case "Life":
        return (
          <ServicesDetailsSidebarLife
            hideTitle={hideTitle}
            hideContact={hideContact}
          />
        );
      case "Motor":
        return (
          <ServicesDetailsSidebarMotor
            hideTitle={hideTitle}
            hideContact={hideContact}
          />
        );
      case "Corporate":
        return (
          <ServicesDetailsSidebarCorporate
            hideTitle={hideTitle}
            hideContact={hideContact}
          />
        );
      case "Travel":
          return (
            <ServicesDetailsSidebar
              hideTitle={hideTitle}
              hideContact={hideContact}
            />
          );
      default:
        return null;
    }
  };

  return (
    <section className="services-details-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          {/* Content */}
          <div className="col-71 order-0 order-lg-2">{children}</div>

          {/* Sidebar */}
          <div className="col-29">{renderSidebar()}</div>
        </div>
      </div>
    </section>
  );
};
