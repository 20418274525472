import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LOGO, W_LOGO } from "../../lib/assets";
import { FaXTwitter } from 'react-icons/fa6';
import { ElfsightWidget } from 'react-elfsight-widget';
import { HashLink } from "react-router-hash-link";

export const FooterOne = () => {
  const [Email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('entry.1045781291', Email);

    fetch('https://docs.google.com/forms/d/e/1FAIpQLSct5y1BPg23Rk-VuY2HX3sb1PCKUvV7cYvqWvz5YIyGENC5Dw/formResponse', {
      method: 'POST',
      body: formData,
      mode:"no-cors"
    })
    .then(() => {
      alert('Form submitted successfully');
      setEmail('');
    })
    .catch((error) => {
      console.error('Error submitting form:', error);
      alert('Error submitting form: ' + error);
    });
  };

  return (
    <footer>
      <div className="footer-area footer-bg">
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-lg-3 col-md-7">
                <div className="footer-widget">
                  <h4 className="fw-title">Information</h4>
                  <div className="footer-info">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-pin"></i>
                        </div>
                        <div className="content">
                          <p>No 4, Lakshmi Avenue, Block 4 F3, 1st Floor, Gandhi 1st Street, Gnanamurthy Nagar, Ambattur, Chennai 600053</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                        <div className="phone_number">
                        <i className="flaticon-phone-call"></i>

                    {/*    <i className="fas fa-phone invert-icon" ></i>
                        <i className="fab fa-whatsapp"></i> */}
                        </div>
                        </div>
                        <div className="content">
                        <div className="phone_number">

                          <a href="tel: +91 79931 67897"> +91 79931 67897</a>
                          
                          </div>


                        </div>
                      </li>
                      <li>
                        <div className="icon">
                        <div className="phone_number">
                        <i className="flaticon-mail"></i>

                    {/*    <i className="fas fa-phone invert-icon" ></i>
                        <i className="fab fa-whatsapp"></i> */}
                        </div>
                        </div>
                        <div className="content">
                        <div className="phone_number">


                        <a href="mailto:support@vrpolicymarket.com">support@vrpolicymarket.com </a>
                          
                          </div>


                        </div>
                      </li>
                    
            
                      <li>
                        <div className="icon">
                          <i className="flaticon-clock"></i>
                        </div>
                        <div className="content">
                          <p>
                            Mon – Sun: 24/7 Support
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 col-md-4 col-sm-4">
                <div className="footer-widget">
                  <h4 className="fw-title">Menu</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/about-4">Company</Link>
                      </li>
                      <li>
                        <Link to="/services-4">Our Services</Link>
                      </li>
                      <li>
                        <Link to="/blog">Our Blog</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Quick Links</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                      <li>
                        <HashLink to="/#testimonialsection">Testimonials</HashLink>
                      </li>
                      <li>
                        <HashLink to="/#EstimateAreaOneForm">Get A Quote</HashLink>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 col-sm-3">
                <div className="footer-widget">
                  <h4 className="fw-title">Insurance Regulatory</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                      <a href="  https://irdai.gov.in/" target="_blank">IRDAI</a>
                      </li>
                     

                      <li>
                      <a href="https://iib.gov.in/" target="_blank">IIB</a>
                      </li>
                      
                      <li>
                      <a href="https://www.cioins.co.in/" target="_blank">Ombudsman</a>
                      </li>
                
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-7">
                <div className="footer-widget">
                  <h4 className="fw-title">Subscribe to Our Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>Sign up for our weekly newsletter to get the latest updates.</p>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="email"
                        placeholder="E-mail"
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <button type="submit">Subscribe</button>
                    </form>
                    <span>We don’t send you any spam</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="left-sider">
                  <div className="f-logo">
                  <p>VR Policy Market</p>
                     {/* <Link to="/">
                       <img src={LOGO} alt="Logo"   style={{ maxWidth: '150px', height: 'auto' }} />
                      </Link>
                      */}
                  
                  </div>
                  <div className="copyright-text">
                    <p>Copyright © Ratoon Infotech | All Right Reserved</p>
                  </div>
                  <div>
                    <ElfsightWidget widgetID="cec14048-a429-4bde-aecd-11e9defe8b86" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="footer-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="https://www.facebook.com/people/VR-Policy-Market/61565985988741/?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/vrpolicymarket?igsh=MW1heWZjZ2xzaTdyOQ==" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@vrpolicymarket" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
