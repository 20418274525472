import React, { useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { LOGO } from "../../lib/assets";

export const Sidebar = () => {
  useEffect(() => {
    $(".menu-tigger").on("click", function () {
      $(".extra-info,.offcanvas-overly").addClass("active");
      return false;
    });
    $(".menu-close,.offcanvas-overly").on("click", function () {
      $(".extra-info,.offcanvas-overly").removeClass("active");
    });
  }, []);

  return (
    <>
      <div className="extra-info">
        <div className="close-icon menu-close">
          <button>
            <i className="far fa-window-close"></i>
          </button>
        </div>
        <div className="logo-side mb-30">
        <Link to="/">
                       <img src={LOGO} alt="Logo"   style={{ maxWidth: '150px', height: 'auto' }} />
           </Link>
                      
        </div>
        <div className="side-info mb-30">
          <div className="contact-list mb-30">
            <h4>Office Address</h4>
            <p>
            No 4, Lakshmi Avenue, Block 4 F3, 1st Floor, Gandhi 1st Street, <br />
            Gnanamurthy Nagar, Ambattur, Chennai 600 053
            </p>
          </div>
          <div className="icon" >
          <div className="phone_number">
          <i className="flaticon-phone-call"></i>

    {/*      <i className="fas fa-phone invert-icon" ></i>
          <i className="fab fa-whatsapp"></i>
*/}
                <a href="tel: +91 79931 67897"> +91 79931 67897</a>
            </div>
                </div>

          <div className="contact-list mb-30">

          </div>
          <div className="contact-list mb-30">
            <h4>Email Address</h4>
            <p>support@vrpolicymarket.com</p>
      {/*      <p>example.mail@hum.com</p> */}
          </div>
        </div>
        <ul className="side-instagram list-wrap">
          <li>
            <a href="#">
              <img src="assets/img/images/sb_insta01.jpg" alt="" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="assets/img/images/sb_insta02.jpg" alt="" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="assets/img/images/sb_insta03.jpg" alt="" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="assets/img/images/sb_insta04.jpg" alt="" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="assets/img/images/sb_insta05.jpg" alt="" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="assets/img/images/sb_insta06.jpg" alt="" />
            </a>
          </li>
        </ul>
        <div className="social-icon-right mt-30">
          <a href="https://www.facebook.com/people/VR-Policy-Market/61565985988741/?mibextid=ZbWKwL">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#">
            <i className="fa fa-twitter"></i>
          </a>
          <a href="#">
            <i className="fab fa-google-plus-g"></i>
          </a>
          <a href="https://www.instagram.com/vrpolicymarket?igsh=MW1heWZjZ2xzaTdyOQ==">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.youtube.com/@vrpolicymarket">
              <i className="fab fa-youtube"></i>
          </a>
       
        </div>
      </div>
      <div className="offcanvas-overly"></div>
    </>
  );
};
