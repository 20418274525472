import React from "react";

export const ServicesDetailsVehicleFaq = () => {
  return (
    <div className="accordion-wrap-three">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Financial Protection:

            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              Motor insurance protects your finances from unexpected expenses, covering repair costs, medical bills, and replacement fees in the event of an accident or loss. With insurance, you can avoid costly out-of-pocket expenses and financial strain. By having Motor insurance, you can safeguard your savings and maintain financial stability.
              </p>
              
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
               Roadside Assistance
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              Roadside Assistance provides help when you need it most, with services like towing, fuel delivery, and lockout assistance. This benefit ensures you're back on the road quickly and safely, minimizing disruptions to your daily routine. With Roadside Assistance, you can drive with confidence, knowing help is just a call away.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
             Peace of Mind
                         </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              Motor insurance provides peace of mind, protecting you from financial stress and worry in the event of an unexpected accident, theft, or natural disaster. With insurance, you can drive with confidence, knowing you're covered against life's uncertainties. By having Motor insurance, you can enjoy a sense of security and tranquility on the road.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
