import React from "react";
import { HeaderSearch } from "./HeaderSearch";
import { MobileMenu } from "./MobileMenu";
import { NavMenus } from "./NavMenus";
import { LOGO } from "../../lib/assets";
import { useStickyMenu } from "../../lib/hooks/useStickyMenu";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const HeaderOne = () => {
  // menu sticky
  useStickyMenu();

  return (
    <header className="transparent-header">
      <div className="heder-top-wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="header-top-left">
                <ul className="list-wrap">
                  <li>
                    <i className="flaticon-location"></i>Chennai, Tamilnadu, India
                  </li>
                  <li>
                    <i className="flaticon-mail"></i>
                    <a href="mailto:support@vrpolicymarket.com">support@vrpolicymarket.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="header-top-right">
                <div className="header-contact">
                  <a href="tel:+917993167897">
                  <div className="phone_number">

                    <i className="flaticon-phone-call"></i> +91 79931 67897

                  </div>
                  </a>
                </div>
                <div className="header-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="https://www.facebook.com/people/VR-Policy-Market/61565985988741/?mibextid=ZbWKwL">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@vrpolicymarket">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/vrpolicymarket?igsh=MW1heWZjZ2xzaTdyOQ==">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="sticky-header" className="menu-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars"></i>
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                  <Link to="/">
                       <img src={LOGO} alt="Logo"   style={{ maxWidth: '150px', height: 'auto' }} />
                      </Link>
                      
                  </div>

                  {/* nav links */}
                  <NavMenus />

                  <div className="header-action d-none d-md-block">
                    <ul className="list-wrap">
                   {/*   <li className="header-search">
                        <a href="#">
                          <i className="flaticon-search"></i>
                        </a>
                      </li>*/}
                      <li className="header-btn">                                          
                        <HashLink to="/#EstimateAreaOneForm"  className="btn btn-two">
                            Get a Quote
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              {/* Mobile Menu  */}
              <MobileMenu />
            </div>
          </div>
        </div>
      </div>

      {/* header-search */}
      {/*<HeaderSearch />*/}
    </header>
  );
};
