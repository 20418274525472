import React from "react";

export const ServicesDetailsHealthFaq = () => {
  return (
    <div className="accordion-wrap-three">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
               Essential Health Benefits

            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              Health insurance plans typically cover essential health benefits, including 
              Doctor visits and consultations,
              Hospitalization and surgical expenses,
              Diagnostic tests and procedures,
              Medications and prescriptions, 
              Preventive care and check-ups   </p>
              
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
               Additional Benefits
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              Some health insurance plans may also cover:

                Alternative therapies (e.g., acupuncture, chiropractic care),
                Vision and dental care,
                Hearing aids and exams,
                Fitness and wellness programs,  
                Travel vaccinations and medications
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Specialized Care
                         </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              Health insurance may cover specialized care, including:

Maternity and newborn care,
Mental health and substance abuse treatment,
Rehabilitation and physical therapy,
Outpatient and inpatient services
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
