import React from "react";
import {
  H4_ABOUT_IMG01,
  H4_ABOUT_IMG02,
  H4_ABOUT_SHAPE,
} from "../../lib/assets";
import { Odometer } from "../Odometer/Odometer";

export const AboutTen = () => {
  return (
    <section className="about-area-ten" style={{ paddingTop: '40px', paddingBottom:'40px'}}>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div className="about-img-wrap-five about-img-wrap-ten">
              <img src={H4_ABOUT_IMG01} alt="" />
              <img src={H4_ABOUT_IMG02} alt="" data-parallax='{"y" : 100 }' />
              <div className="experience-wrap">
                <h2 className="title">
                  25 <span>Years Of Experience</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-lg-6"  style={{ paddingTop: '0px', paddingBottom:'0px'}}>
            <div className="about-content-five about-content-ten">
              <div className="section-title-two mb-30">
                <span className="sub-title">Get To know US</span>
                <h2 className="title">
                Why We Are the Best Insurance Consultant: Commitment to Excellence in Every Policy.                </h2>
              </div>
              <p>
              We offer a wide range of insurance solutions designed to meet diverse needs. Whether you’re looking for health, auto, home, or life insurance, our extensive portfolio ensures that you find the perfect coverage tailored to your specific requirements. Our policies are crafted to provide comprehensive protection, so you can have peace of mind knowing you’re covered from all angles.
              </p>
              <p>
              Our commitment to outstanding customer service sets us apart. Our knowledgeable and friendly team is always ready to assist you with personalized advice, quick responses, and support throughout the insurance process. We prioritize your needs and work tirelessly to ensure you receive the best service, whether you’re making a claim or exploring your coverage options.
              </p>
              <div className="about-success-wrap">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <i className="flaticon-family"></i>
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <Odometer end={63} />%
                      </h2>
                      <p>Insured Customers</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="flaticon-trophy"></i>
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <Odometer end={95} />%
                      </h2>
                      <p>Satisfied Award</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-shape-five">
        <img src={H4_ABOUT_SHAPE} alt="" />
      </div>
    </section>
  );
};
