import React from "react";
import { Layout } from "../../layouts/Layout";
import { CHECK_ICON, SERVICES_DETAILS05 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";

const ServicesDetailsPageFive = () => {
  const InsuranceType = "Corporate"
  return (
    <Layout breadcrumb={"Services"} title={"Corporate Group Insurance"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper hideTitle InsuranceType={InsuranceType}>
        <div className="services-details-wrap">
          <div className="services-details-content">
            <h2 className="title">Insure your greatest asset - your people</h2>
            <p>
            As a business owner, your employees are your most valuable assets, and their well-being is crucial to the success of your company. Protecting them is essential to ensuring the productivity and growth of your business.

            </p>
            <div className="services-details-thumb-two">
              <img src={SERVICES_DETAILS05} alt="" />
            </div>
            <p>
            Corporate Group Insurance offers a comprehensive solution to provide financial security and peace of mind for your workforce. By investing in this insurance, you can help ensure the well-being and productivity of your employees, ultimately safeguarding the future of your business.
            </p>
            <div className="speed-optimized-wrap">
              <h4 className="title-two">Benefits</h4>
              <p>
              Corporate Group Insurance offers a wide range of benefits for both employers and employees.Group insurance can be more cost-effective than individual policies, streamlining benefits administration and providing flexibility in policy choices
              </p>
            </div>
            <div className="sd-inner-wrap-five">
              <div className="row">
                <div className="col-50">
                  <div className="left-side-content">
                    <h4 className="title-two">Employer Benefits</h4>
                    <p>
                    Corporate Group Insurance can lead to improved employee morale, reduced turnover rates, and ultimately, business continuity and protection of their most valuable assets - their employees. This comprehensive solution helps employers demonstrate their commitment to employee well-being, setting them apart from competitors and driving long-term success.
                    </p>
                  </div>
                </div>
                <div className="col-50">
                  <div className="right-side-content">
                    <h4 className="title-two">Employee Benefits</h4>
                    <p>when an unknown printer took a galley type</p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Reduced Financial Stress
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Increased Job Satisfaction
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Sense of Security and Stability
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Improved Well-Being
                      </li>
                    </ul>
                   
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="company-benefit-wrap">
              <h4 className="title-two">Secure Your Team, Amplify Your Success</h4>
              <p>
              In conclusion, Corporate Group Insurance is a valuable investment for both employers and employees. It provides a comprehensive benefits package that offers financial security, peace of mind, and access to benefits that may not be affordable individually. By investing in Corporate Group Insurance, employers can attract and retain top talent, increase productivity, and enhance their business reputation. Employees benefit from financial protection, improved well-being, and increased job satisfaction. Overall, Corporate Group Insurance is a win-win solution that promotes a secure, productive, and successful work environment. It is an essential component of a competitive benefits package and a key factor in driving business growth and success.
              </p>

              {/* faq */}
             {/* <ServicesDetailsFaq /> */}
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    {/*  <BrandSeven /> */}
    </Layout>
  );
};

export default ServicesDetailsPageFive;
