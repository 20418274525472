import React from "react";
import { Layout } from "../../layouts/Layout";
import { BlogPageWrapper } from "../../components/BlogAreas/BlogPageWrapper";
import { Link } from "react-router-dom";
import {
  BLOG_AVATAR01,
  BLOG_DETAILS01,
  BLOG_DETAILS02,
  CHECK_ICON,
} from "../../lib/assets";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { BlogAuthorInfo } from "../../components/BlogAreas/BlogAuthorInfo";
import { BlogComments } from "../../components/BlogAreas/BlogComments";
import { BlogCommentForm } from "../../components/BlogAreas/BlogCommentForm";

const BlogDetailsPage = () => {
  return (
    <Layout breadcrumb={"Blog Details"} title={"Blog Details"}>
      <BlogPageWrapper>
        {/* image */}
        <div className="blog-details-thumb">
          <img src={BLOG_DETAILS01} alt="" />
        </div>

        {/* content */}
        <div className="blog-details-content">
          {/*Top 5 reasons why you need Life Insurance in your 30s*/}
          <h2 className="title">
          </h2>

          <div className="blog-meta-three">
          {/*  <ul className="list-wrap">
              <li>
                <i className="far fa-calendar"></i>22 Jan, 2023
              </li>
              <li>
                <img src={BLOG_AVATAR01} alt="" />
                by <Link to="/blog-details">Kat Doven</Link>
              </li>
              <li>
                <i className="fas fa-tags"></i>
                <Link to="/blog">Finance,</Link>
                <Link to="/blog">Business</Link>
              </li>
              <li>
                <i className="flaticon-speech-bubble"></i>
                <Link to="/blog-details">05 Comments</Link>
              </li>
            </ul> */}
          </div>
            <p>

            </p>
            <section id = "TopFiveReasons" className="blog-meta-three" >
              <p>
         

            <h4 className="title-two">Top 5 reasons why you need Life Insurance in your 30s</h4>
          
              <h6>Income Replacement</h6> If you have a family or dependents, life insurance ensures they can maintain their standard of living even if you're no longer around to provide for them.
              <p></p>
              <h6> Pay Off Debts:</h6>  Life insurance can help pay off outstanding debts, such as a mortgage, car loan, or credit cards, so your loved ones aren't burdened with these expenses.
              <p></p>
              <h6>Funeral Expenses:</h6> Life insurance can cover funeral costs, which can be a significant burden on your loved ones.
              <p></p>
              <h6>Future Goals:</h6>  Life insurance can help ensure your long-term goals, such as saving for your children's education or retirement, are still achievable even if you're not around.
              <p></p>

              <h6>Lock in Lower Rates:</h6> Buying life insurance in your 30s can lock in lower rates, as premiums tend to increase with age and health changes, making it a smart investment for your financial future.

              <p></p>

                Remember, life insurance is a crucial aspect of financial planning, and your 30s are a great time to invest in a policy that can provide peace of mind and financial security for you and your loved ones.
              </p>
              <p>

          </p>
          </section>
          <blockquote>
            <p>
              “ Life's unpredictable, but your protection doesn't have to be!! ”
            </p>
          </blockquote>
          <h4 className="title-two">The Importance of Disability Insurance for Small Business Owners</h4>
          <p>
          As a small business owner, you wear many hats and are often the backbone of your company. But what would happen if you suddenly became unable to work due to an illness or injury? Disability insurance can provide a financial safety net, ensuring your business and personal life are protected.
          </p>

          <div className="bd-inner-wrap">
            <div className="row align-items-center">
              <div className="col-46">
                <div className="thumb">
                  <img src={BLOG_DETAILS02} alt="" />

                {/*  <VideoPlayerOne
                    src={"https://www.youtube.com/watch?v=6mkoGSqTqFI"}
                  />*/}
                </div>
              </div>

              <div className="col-54">
                <div className="content">
                  <h3 className="title-two">
                  Why Disability Insurance is Crucial for Small Business Owners:
                  </h3>
                  <h6>Income Protection</h6>
                  
                  <h6>Business Continuity </h6> 
                  <h6>Personal Financial Security</h6>
                  <h6>Employee Protection </h6>
                  
                  <h6>Tax Benefits</h6>
                  
                {/*  <ul className="list-wrap">
                    <li>
                      <img src={CHECK_ICON} alt="" />
                      Financial Protection
                    </li>
                    <li>
                      <img src={CHECK_ICON} alt="" />
                      Risk Management
                    </li>
                    <li>
                      <img src={CHECK_ICON} alt="" />
                      Security for Loved Ones
                    </li>
                  </ul>*/}
                </div>
              </div>
            </div>
          </div>

          <p>
            <h4>Don't Risk Your Business and Financial Future
            </h4>
            Disability insurance is often overlooked, but it's a vital component of a comprehensive financial plan. Don't wait until it's too late – protect your business, income, and personal well-being with disability insurance today.</p>
         
            <section id = "VitalComponent" className="blog-meta-three" >
              <p>
         

            <h4 className="title-two">Car insurance is a vital component of responsible vehicle ownership</h4>
          
              <p>Car insurance is a crucial aspect of responsible vehicle ownership, providing financial protection, legal compliance, and peace of mind. It helps cover unexpected expenses in the event of an accident, theft, or damage, ensuring you don't face financial ruin. Additionally, car insurance protects you against lawsuits and financial losses if you're found liable for an accident. By investing in car insurance, you demonstrate responsible ownership and ensure a safe and secure driving experience for yourself and others on the road.</p>
                           </p>
                      </section>
         
          <div className="bd-content-bottom">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="post-tags">
                  <h5 className="title">Tags:</h5>
                  <ul className="list-wrap">
                    <li>
                      <a href="#">Life Insurance</a>
                    </li>
                    <li>
                      <a href="#">Disability Insurance</a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* bottom */}
              <div className="col-md-5">
                <div className="blog-post-share">
                  <h5 className="title">Share:</h5>
                  <ul className="list-wrap">
                    <li>
                      <a href="https://www.facebook.com/people/VR-Policy-Market/61565985988741/?mibextid=ZbWKwL">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@vrpolicymarket">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/vrpolicymarket?igsh=MW1heWZjZ2xzaTdyOQ==">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* auhor info */}
        <BlogAuthorInfo />

        {/* comments */}
        <BlogComments />

        {/* comment form */}
        <BlogCommentForm />
      </BlogPageWrapper>
    </Layout>
  );
};

export default BlogDetailsPage;
