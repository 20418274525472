import React from "react";
import { Layout } from "../../layouts/Layout";
import { ServicesDetailsHealthFaq } from "../../components/ServicesDetails/ServicesDetailsHealthFaq";
import {
  SD_WORKING_IMG01,
  SD_WORKING_IMG02,
  SERVICES_DETAILS02,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";

const ServicesDetailsPageTwo = () => {
  const InsuranceType = "Health"
  return (
    <Layout breadcrumb={"Services"} title={"Health Insurance"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper InsuranceType={InsuranceType}>
        <div className="services-details-wrap">
          <div className="services-details-content">
            <h2 className="title">
             Insure Your Health, Ensure Your Happiness

            </h2>
            <p>
            Health insurance is a type of insurance policy that covers the cost of medical and surgical expenses incurred by the policyholder. It provides financial protection against medical emergencies, illnesses, and injuries, ensuring that you receive necessary treatment without financial strain.
            </p>
            <div className="sd-inner-wrap-two">
              <div className="row align-items-center">
                <div className="col-58">
                  <div className="thumb">
                    <img src={SERVICES_DETAILS02} alt="" />
                  </div>
                </div>
                <div className="col-42">
                  <div className="services-details-list">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-business-presentation"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Reduces Out-of-Pocket Expenses: </h5>
                          <p>
                          Covers a significant portion of medical expenses
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-investment"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Saves on Taxes:</h5>
                          <p>
                          Premiums may be tax-deductible, reducing your taxable income.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-taxes"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Access to Quality Care</h5>
                          <p>
                          Ensures you receive necessary medical treatment without delay or financial burden.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="industrial-working-wrap">
              <h2 className="title-two">Choosing the Right Health Insurance:
              </h2>
              <p className="info-one">
              Selecting the right health insurance policy is a crucial decision that can impact your financial and physical well-being. With numerous options available, it's essential to choose a policy that aligns with your needs, budget, and lifestyle. Consider your age, health, income, and family size when evaluating policies. 
              </p>
              <div className="row">
               {/* <div className="col-md-6">
                  <img src={SD_WORKING_IMG01} alt="" />
                </div>
                <div className="col-md-6">
                  <img src={SD_WORKING_IMG02} alt="" />
                </div>*/}
              </div>
              <p className="info-two">
              Compare coverage options, deductibles, and co-pays to ensure you're getting the best value. Don't forget to check network providers, premium costs, and policy features. By taking the time to research and compare policies, you'll find the perfect coverage for a healthy and secure tomorrow. Remember, a well-chosen health insurance policy is an investment in your peace of mind and well-being.
              </p>
            </div>
            <div className="company-benefit-wrap">
              <h2 className="title-two">What Does Health Insurance Cover? A Comprehensive Guide              </h2>
              <p>
              Health insurance is a vital investment in your well-being, providing financial protection against medical expenses. But what exactly does health insurance cover? Below, we'll delve into the details of health insurance coverage, so you can make informed decisions about your healthcare.
                 </p>

              {/* faq accordion */}
              <ServicesDetailsHealthFaq />
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    {/*  <BrandSeven /> */}
    </Layout>
  );
};

export default ServicesDetailsPageTwo;
