import React from "react";
import { BiSpaceBar } from "react-icons/bi";
import { CgSpaceBetween } from "react-icons/cg";
import { TbSpace } from "react-icons/tb";
import { Link } from "react-router-dom";

export const CtaAreaThree = () => {
  return (
    <section className="cta-area-three">
      <div className="container">
        <div className="cta-inner-wrap-three">
          <div className="row align-items-center">
            <div className="col-lg-9">
              <div className="cta-content">
                <div className="cta-info-wrap cta-info-wrap-two">
                 
                  <div className="content">
                    <span><b>Contact For More Info</b></span>
                    <div className="phone_number">

                   {/* <i className="fas fa-phone invert-icon" ></i>
                    <i className="fab fa-whatsapp"></i>*/}

                    <a href="tel: +91 79931 67897">   +91 79931 67897</a>
                    </div>
                   <p></p>
                  {/*  <i className="flaticon-mail"></i> */}<a href="mailto:support@vrpolicymarket.com">support@vrpolicymarket.com</a>

                  </div>
                </div>
                <h2 className="title">
                  Let’s Request a Schedule For Free Consultation
                </h2>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="cta-btn text-end">
                <Link to="/contact" className="btn btn-three">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
