import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { SERVICES_DETAILS01 } from "../../lib/assets";
import {brochure_Home} from '../../lib/assets'

export const ServicesDetailsSidebarHome = ({
  hideContact = false,
  hideTitle = false,
}) => {
  // State management for form fields
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [insuranceType, setInsurancetype] = useState("Home-Insurance");


  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Prepare form data
    const formData = new FormData();
    formData.append('entry.2005620554', Name); 
    formData.append('entry.1045781291', Email); 
    formData.append('entry.1166974658', phone); 

    formData.append('entry.839337160', message); 
    formData.append('entry.352411605', insuranceType); 


    fetch('https://docs.google.com/forms/d/e/1FAIpQLSct5y1BPg23Rk-VuY2HX3sb1PCKUvV7cYvqWvz5YIyGENC5Dw/formResponse', {
      method: 'POST',
      body: formData,
      mode: 'no-cors'
    })
    .then(() => {
      alert('Form submitted successfully');
      // Clear form fields upon successful submission
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
    })
    .catch((error) => {
      console.error('Error submitting form:', error);
      alert('Error submitting form: ' + error);
    });
  };

  return (
    <aside className="services-sidebar">
      {/* Brochure */}
      <div className="services-widget">
        <h4 className="sw-title">Brochure</h4>
        <div className="services-brochure-wrap">
          <p>Click on the download below to download our Home Insurance Brochure</p>
          <a
            href={brochure_Home}
            target="_blank"
            rel="noopener noreferrer" // Added for security
            download
            className="download-btn"
          >
            <i className="far fa-file-pdf"></i> PDF. Download
          </a>
        </div>
      </div>

      {/* Contact */}
      {hideContact ? null : (
        <div className="services-widget services-sidebar-contact">
          <h4 className="title">If You Need Any Help Contact Us</h4>
          <a href="tel:+917993167897">

            <div className="phone_number">
            <i className="flaticon-phone-call"></i> +91 79931 67897
            </div>
          </a>
        </div>
      )}

      {/* Get A Free Quote */}
      <div className="services-widget">
        <h4 className="sw-title">Get a Free Quote</h4>
        <div className="services-widget-form">
          <form onSubmit={handleSubmit}>
            <div className="form-grp">
              <input
                type="text"
                placeholder="Full Name"
                value={Name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-grp">
              <input
                type="email"
                placeholder="E-mail"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className ="form-grp">
            <input type="tel"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />

            </div>
            <div className="form-grp">
              <input
                type="text"
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                
              />
            </div>
            <button type="submit" className="submit-btn">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </aside>
  );
};
