import React, { useState } from "react";
import { H2_REQUEST_SHAPE01, H2_REQUEST_SHAPE02 } from "../../lib/assets";

export const RequestAreaTwo = () => {
  // State management for form fields
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('entry.2005620554', Name); 
    formData.append('entry.1045781291', Email); 
    formData.append('entry.1166974658', phone); 
    formData.append('entry.839337160', message); 

    fetch('https://docs.google.com/forms/d/e/1FAIpQLSct5y1BPg23Rk-VuY2HX3sb1PCKUvV7cYvqWvz5YIyGENC5Dw/formResponse', {
      method: 'POST',
      body: formData,
      mode: 'no-cors'
    })
    .then(() => {
      alert('Form submitted successfully');
      // Clear form fields upon successful submission
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
    })
    .catch((error) => {
      console.error('Error submitting form:', error);
      alert('Error submitting form: ' + error);
    });
  };

  return (
    <section className="request-area-two" style={{ paddingTop: '90px', paddingBottom:'90px'}}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="request-content-two">
              <div className="section-title-two white-title mb-15 tg-heading-subheading animation-style3">
                <h2 className="title tg-element-title">Request a Call Back</h2>
              </div>
              <p>Need Help in Finding the Best Insurance For You and Your Family?</p>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="request-form-wrap">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        type="text"
                        placeholder="Full Name"
                        value={Name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        type="email"
                        placeholder="E-mail"
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        type="tel"
                        placeholder="Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        type="text"
                        placeholder="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                  </div>
                  
                  <div className="col-md-6 align-center">
                    <button type="submit">Send Now</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="request-shape-wrap">
        <img src={H2_REQUEST_SHAPE01} alt="Request Shape 01" />
        <img
          src={H2_REQUEST_SHAPE02}
          alt="Request Shape 02"
          data-aos="fade-left"
          data-aos-delay="200"
        />
      </div>
    </section>
  );
};
